<template>
  <div class="join-us">
    <web-header />
    <el-carousel
      :autoplay="false"
      class="join-us-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="join-us-head">
          <div class="join-us-head-left">
            <div class="join-us-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">加入我们</div>
            </div>
            <div class="join-us-head-content">
              和及猫云一起，帮助全球1000万企业高效连接用户
            </div>
          </div>
          <img
            src="../assets/img/join-us/show_img@2x.png"
            alt="首页电视图"
            class="join-us-right-img"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="join-us-our-teams">
      <div class="our-teams-title">
        <div class="vertical" />
        <div class="title">我们的团队</div>
      </div>
      <div class="our-teams-title-english">Our teams</div>
      <el-carousel
      height="646px"
      :autoplay="false"
      class="our-teams-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="our-teams-carousel-item">
          <img
            src="../assets/img/back-left@2x.png"
            alt
            class="our-teams-left-img"
          />
          <img
            src="../assets/img/back-left@2x.png"
            alt
            class="our-teams-right-img"
          />
          <div class="item-content">
            <div class="item-left">
              <img src="../assets/img/home/数据展示little@2x.png" alt />
              <div class="round" />
            </div>
            <div class="item-right">
              <div class="round" />
              <img src="../assets/img/home/数据展示little@2x.png" alt />
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    </div>
    <div class="join-us-welfare">
      <div class="welfare-left">
        <div class="welfare-left-title">
          <img src="../assets/img/join-us/01 @2x.png" alt />
          <div class="title-line">
            <div class="title">
              <span>追</span>逐梦想的发展平台
            </div>
            <div class="line" />
          </div>
        </div>
        <div class="welfare-left-content">
          人才梯队的建设是及猫云发展的重要组成部分，企业的进步
          得益于及猫云小伙伴的智慧、努力与贡献。及猫云为员工提
          供了富有竞争力的晋升体系，定期的培训学习，增加新的技
          能让员工的能力得到更好的提升与发展，我们既是一家企业
          更是一所共同进步的企业。
        </div>
      </div>
      <div class="welfare-right">
        <div class="welfare-right-title">
          <img src="../assets/img/join-us/02 @2x.png" alt />
          <div class="title-line">
            <div class="title">
              <span>令</span>人心动的福利待遇
            </div>
            <div class="line" />
          </div>
        </div>
        <div class="welfare-right-content">
          <div
            class="item"
            v-for="item in welfareData"
            :key="item.id"
          >
            <div class="item-title">
              <div class="vertical" />
              <div class="title">{{ item.tit }}</div>
            </div>
            <div
              class="item-content"
            >{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="join-us-open-position">
      <div class="open-position-title">
        <div class="vertical" />
        <div class="title">开放职位</div>
      </div>
      <div class="open-position-title-english">Open position</div>
      <div class="open-position-content">
        <div class="tab-head">
          <div
            :class="item.id === activeId ? 'tab-item active' :' tab-item'"
            v-for="item in tabData"
            :key="item.id"
          >
            <div @click="tabClick(item.id)">{{ item.title }}</div>
            <div :class=" item.id === activeId ? 'tab-line-trigonum down' : 'tab-line-div'" />
          </div>
        </div>
        <div class="tab-content">
          <div class="title">岗位职责：</div>
          <div
            class="content-item"
            v-for="(item, index) in dutyData"
            :key="index"
          >{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="electric-footer">
      <web-footer />
    </div>
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'join-us',
  components: {
    webHeader,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      welfareData: [
        {
          id: 1,
          tit: '五险一金',
          content: '缴纳养老保险、医疗保险、失业保险、工伤保险、生育保险与住房公积金'
        },
        {
          id: 2,
          tit: '带薪年假',
          content: '按照工作年限为员工提供带薪年休假'
        },
        {
          id: 3,
          tit: '节日、生日礼物',
          content: '法定或者特定节日时提供礼物，举行派对'
        },
        {
          id: 4,
          tit: '定期团建',
          content: '定期组织员工聚会'
        }
      ],
      activeId: 1,
      tabData: [
        {
          id: 1,
          title: '销售总监'
        },
        {
          id: 2,
          title: '客户经理'
        },
        {
          id: 3,
          title: '市场策划'
        },
        {
          id: 4,
          title: 'JAVA开发工程师'
        }
      ],
      dutyData: [
        '1、负责制定销售战略、组织实施完整的销售计划，领导团队将计划转变为销售结果',
        '2、组织开发多种销售手段，完成销售计划；',
        '3、负责销售团队的建设，帮助建立、补充、发展、培养销售队伍；',
        '4、根据公司战略目标，合理有效的拆分销售业绩目标；',
        '5、负责销售体系搭建及优化，包含但不限于销售团队组建、培训、销售政策制定实施等；',
        '6、参与公司重大营销合同的谈判与签订工作； '
      ]
    }
  },
  methods: {
    tabClick (val) {
      this.activeId = val
    }
  }
}
</script>

<style lang="scss" scoped>
.join-us {
  width: 100%;
  .join-us-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .join-us-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 746px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-bottom: 150px;
    .join-us-head-left {
      font-family: SourceHanSansCN;
      color: #071B3C;
      margin-left: 86px;
      margin-top: -30px;
      .join-us-head-title {
        font-size: 60px;
        display: flex;
        flex-direction: row;
        font-weight: 800;
        .title {
          z-index: 1;
          margin-left: -25px;
        }
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -13px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
         .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
      }
      .join-us-head-content {
        font-size: 35px;
        font-weight: 500;
        margin-top: 72px;
        width: 720px;
      }
    }
    .join-us-right-img {
      width: 649px;
      height: 452px;
      margin-right: 163px;
    }
  }
  .join-us-our-teams {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 110px;
    padding-bottom: 79px;
    .our-teams-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      .vertical {
        width: 11px;
        height: 83px;
        background: #FFE50C;
        border-radius: 5px;
        margin-right: -10px;
        margin-top: -24px;
        z-index: 1;
      }
      .title {
        width: 243px;
        height: 79px;
        background: #0E0900;
        border-radius: 10px;
        font-size: 35px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFE50C;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .our-teams-title-english {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: rgba(7, 27, 61, 0.56);
      margin-top: 33px;
    }
    .our-teams-carousel {
      width: 100%;
      ::v-deep .el-carousel__button {
        border: 1px solid #FFE50C;
        background: #FFFFFF;
      }
      ::v-deep .el-carousel__indicator.is-active button {
        background: #FFE50C;
      }
      .our-teams-carousel-item {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-top: 123px;
        .our-teams-left-img {
          width: 275px;
          height: 326px;
          margin-top: 120px;
        }
        .our-teams-right-img {
          width: 273px;
          height: 326px;
          margin-top: 40px;
        }
        .item-content {
          position: absolute;
          display: flex;
          flex-direction: row;
          margin-left: 185px;
          img {
            width: 725px;
            height: 458px;
            z-index: 1;
          }
          .round {
            width: 48px;
            height: 48px;
            background: #FFF499;
            border-radius: 50%;
          }
          .item-left {
            margin-right: 56px;
            display: flex;
            flex-direction: row;
            .round {
              margin-top: 412px;
              margin-left: -40px;
            }
          }
          .item-right {
            display: flex;
            flex-direction: row;
            .round {
              margin-right: -40px;
              margin-top: -4px;
            }
          }
        }
      }
    }
  }
  .join-us-welfare {
    width: 100%;
    height: 979px;
    background: #F8F8F8 url('../assets/img/join-us/back_num@2x.png') bottom no-repeat;
    background-size: 923px 119px;
    display: flex;
    flex-direction: row;
    .welfare-left {
      margin-left: 78px;
      .welfare-left-title {
        display: flex;
        flex-direction: column;
        margin-left: 48px;
        img {
          width: 141px;
          height: 86px;
          margin-top: 115px;
        }
        .title-line {
          margin-top: 55px;
          margin-bottom: 107px;
          .title {
            font-size: 40px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #000000;
            span {
              font-size: 45px;
            }
          }
          .line {
            width: 373px;
            height: 6px;
            background: #FFD400;
            border-radius: 3px;
            margin-top: -16px;
            margin-left: -6px;
          }
        }
      }
      .welfare-left-content {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 41px;
        width: 577px;
      }
    }
    .welfare-right {
      margin-left: 206px;
      .welfare-right-title {
        img {
          width: 211px;
          height: 111px;
          margin-top: 129px;
          margin-left: 630px;
        }
        .title-line {
          margin-top: 47px;
          margin-bottom: 101px;
          margin-left: 430px;
          .title {
            font-size: 40px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #000000;
            span {
              font-size: 45px;
            }
          }
          .line {
            width: 370px;
            height: 6px;
            background: #FFD400;
            border-radius: 3px;
            margin-top: -16px;
          }
        }
      }
      .welfare-right-content {
        display: flex;
        flex-wrap: wrap;
        .item {
          margin-right: 50px;
          margin-bottom: 96px;
          .item-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            .vertical {
              width: 4px;
              height: 26px;
              background: #FFD400;
              border-radius: 2px;
              margin-right: 16px;
            }
            .title {
              font-size: 26px;
              font-family: PingFang SC;
              font-weight: 600;
              color: #000000;
            }
          }
          .item-content {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
            width: 440px;
            margin-left: 14px;
            margin-top: 23px;
          }
        }
      }
    }
  }
  .join-us-open-position {
    display: flex;
    flex-direction: column;
    width: 100%;
    .open-position-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 122px;
      .vertical {
        width: 11px;
        height: 83px;
        background: #FFE50C;
        border-radius: 5px;
        margin-right: -10px;
        margin-top: -14px;
        z-index: 1;
      }
      .title {
        font-size: 35px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFE50C;
        width: 243px;
        height: 79px;
        background: #0E0900;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .open-position-title-english {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(7, 27, 61, 0.56);
      margin-top: 44px;
      margin-bottom: 135px;
      display: flex;
      justify-content: center;
    }
    .open-position-content {
      .tab-head {
        display: flex;
        border-bottom: 2px solid #A3ACB4;
        margin-left: 119px;
        margin-right: 102px;
        .tab-item {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-right: 109px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .tab-item.active {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #FFCE00;
        }
        .tab-line-div {
          position: relative;
          width: 192px;
          margin-top: 33px;
          float: left;
          margin-bottom: -2px;
        }
        .tab-line-trigonum {
          position: relative;
          border-top: 2px solid #FFD858;
          width: 192px;
          margin-top: 33px;
          float: left;
          margin-bottom: -2px;
        }
        .tab-line-trigonum:before,
        .tab-line-trigonum:after {
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border: solid transparent;
          pointer-events: none;
          content: "";
        }
        .tab-line-trigonum.down:before {
          border-bottom-color: #FFD858;
          border-width: 12px;
          left: 49%;
          margin-left: -11px;
          bottom: 100%;
        }
        .tab-line-trigonum.down:after {
          border-bottom-color: #fff;
          border-width: 8px;
          left: 51%;
          margin-left: -11px;
          bottom: 100%;
        }
      }
      .tab-content {
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        height: 371px;
        background: rgba(255,244,153,0.07);
        border: 2px solid #000000;
        border-radius: 10px;
        margin-left: 106px;
        margin-right: 100px;
        margin-top: 55px;
        .title {
          font-size: 24px;
          margin-left: 64px;
          margin-top: 49px;
          margin-bottom: 22px;
        }
        .content-item {
          font-size: 22px;
          margin-left: 63px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>
