<template>
  <div class="company-about">
    <el-carousel
      :autoplay="false"
      class="company-about-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="company-about-head">
          <div class="company-about-head-left">
            <div class="company-about-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">及猫云</div>
            </div>
            <div class="company-about-head-content">
              智能融合通信一体化开放API引领者
            </div>
          </div>
          <img
            src="../../assets/img/home/多个盒子@2x.png"
            alt="首页电视图"
            class="head-right-img"
          />
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="company-about-brief">
      <div class="brief-left">
        <div class="brief-left-title">
          <div class="title">企业介绍</div>
          <div class="line" />
        </div>
        <div class="brief-left-tips">
          及猫云上海希奥信息科技股份有限公司旗下的AI VoiceBot服务品牌
        </div>
        <div class="brief-left-content">
          <!-- <div class="item">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;希奥信息成立于2006年，新三板上市企业(证券代码：430632），高新
          </div>
          <div class="item">
            技术企业，总部位于上海，并在北京、济南、郑州多地设有办事处；是专业
          </div>
          <div class="item">
            的全球通信能力开放平台、AI语音交互、企业大数据的智能通讯云服务商。
          </div>
          <div class="item">
            拥有基于云端的云计算、大数据、人工智能技术；通过TCRM及猫云CC、C
          </div>
          <div class="item">
            PAAS通信能力协助平台 （智慧短信、视频彩信、语音能力）云呼叫插件系
          </div>
          <div class="item">
            统、及猫云掘贝通、企微助手等插件产品构建的企业数字化矩阵为客户提升
          </div>
          <div class="item">
            通信触达能力，是中国领先的云通讯插件能力服务商。
          </div> -->

          <div class="item">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;及猫云隶属希奥信息成立于2006年9月，及猫云是人机语音、视频交互赛道领先的企业级AI会员运营的服务公司，主营业务是基于人机对话交互技术，产品包括5G视频数字人外呼，AI语音机器人、智慧短彩信、5G消息及API接口插件等，旨在帮助百万级会员企业千人千面触达会员，实现精细化会员激活、会员唤醒的运营服务。
          </div>
          <div class="item">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;希奥信息2014年新三板上市(证券代码：430632）总部位于上海，并在北京、济南、郑州多地设分支机构，同时是上海市高新技术企业、2022年度“专精特新”企业；拥有多方通信、呼叫中心、增值业务等多项全国业务资质！ISO质量、安全认证、二级等保及近百项行业相关著作权技术！
          </div>
          <div class="item">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;用AI赋能场景，让会员复购落地，及猫云为客户和用户提供的AI能力和深度场景化AI
            VoiceBot服务。目前，公司已深入全国31个省市，所涉及场景包括美妆、直播达人、母婴、食品、鞋服等多个零售行业，线下商超、商业综合体、医药门店、企服等多个本地生活服务领域。
          </div>
        </div>
      </div>
      <div class="brief-right">
        <div class="brief-right-title">希奥信息</div>
        <div class="brief-right-content">
          <div class="line" />
          <div class="text">帮助全球1000万企业高效连接用户</div>
          <div class="line" />
        </div>
      </div>
    </div>
    <div class="company-about-culture">
      <div class="culture-title">
        <div class="title">企业文化</div>
        <div class="line" />
      </div>
      <div class="culture-content">
        <div
          class="item"
          v-for="item in cultureData"
          :key="item.id"
        >
          <div class="item-title">
            <div class="title">{{ item.title }}</div>
            <div class="round" />
          </div>
          <div class="item-tips">{{ item.tips }}</div>
          <div class="item-content">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="company-about-development-process">
      <div class="development-process-title">
        <div class="title">发展历程</div>
        <div class="line" />
      </div>
      <div class="development-process-content">
        <div class="content-left">
          <div
            class="item"
            v-for="item in activities"
            :key="item.id"
            @mouseover="tabActives(item.id)"
          >
            <div :class="item.id === activeId ? 'title active' : 'title'">
              {{ item.timestamp }}
            </div>
            <div :class="item.id === activeId ? 'round-line active' : 'round-line'">
              <div class="round-big">
                <div
                  class="position-line"
                  v-show="item.id === activeId"
                />
                <div class="round-little" />
              </div>
              <div class="line"></div>
            </div>
          </div>
        </div>
        <div class="content-right">
          <div class="title">{{ activeObject.timestamp }}</div>
          <div
            class="item"
            v-for="item in activeObject.content"
            :key="item.id"
          >
            <div class="left-trigonum"></div>
            <div class="right-content">
              <div class="date">{{ item.date }}</div>
              <div
                class="text"
                v-for="(textItem, textIndex) in item.text"
                :key="textIndex"
              >
                {{ textItem }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="company-about-honor" v-if="false">
      <div class="company-about-honor-head">
        <div class="title">荣誉资质</div>
        <div class="line" />
      </div>
      <div class="company-about-honor-content">
        <div
          class="item"
          v-for="item in honorList"
          :key="item.id"
        >
          <img :src="item.img" />
          <div class="item-text">{{ item.text }}</div>
        </div>
      </div>
    </div>
    <div class="company-about-contact-us">
      <div class="contact-us-head">
        <div class="contact-us-title">联系我们</div>
        <div class="contact-us-line" />
      </div>
      <div class="contact-us-content">
        <div class="item">
          <div class="left" />
          <div class="right">
            <div class="right-tit">产品咨询</div>
            <div class="text">如果您对我们的产品感兴趣，想体验及猫云</div>
            <div class="text">更多产品请随时联系我们哦</div>
            <div class="phone">联系手机：15317237848</div>
          </div>
        </div>
        <div class="item">
          <div class="right">
            <div class="right-tit">人才引进</div>
            <div class="text">与及猫云一起，帮助全球1000万企业高效</div>
            <div class="text">连接用户</div>
            <div class="phone">邮箱：hr@sioo.cn</div>
          </div>
          <div class="left" />
        </div>
      </div>
      <div class="contact-us-address">
        <img src="../../assets/img/aboutUs/plat.png" />
        <div class="contact-us-address-text">
          公司地址：上海市黄浦区成都北路500号峻岭广场23层 2301
        </div>
      </div>
    </div>
    <web-footer />
    <fixed-dialog />
  </div>
</template>

<script>
import webFooter from '../../components/WebFooter/index.vue'
import fixedDialog from '../../components/FixedDialog/index.vue'

export default {
  name: 'company-about',
  components: {
    webFooter,
    fixedDialog
  },
  data () {
    return {
      cultureData: [
        {
          id: 1,
          title: '01',
          tips: '定位',
          content: '世界级通信专家'
        },
        {
          id: 2,
          title: '02',
          tips: '愿景',
          content: '帮助全球1000万企业高效连接用户'
        },
        {
          id: 3,
          title: '03',
          tips: '使命',
          content: '用通信构建未来世界'
        },
        {
          id: 4,
          title: '04',
          tips: '价值观',
          content: '以创新成就用户持续艰苦奋斗'
        }
      ],
      activeId: 1,
      activeObject: {},
      activities: [
        {
          id: 1,
          timestamp: '2023',
          content: [
            {
              id: 1,
              date: '2月',
              text: ['及猫云AI调度系统立项']
            },
            {
              id: 2,
              date: '3月',
              text: ['荣获上海市“专精特新”企业称号']
            }, {
              id: 3,
              date: '4月',
              text: ['及猫云“AI大脑”上线']
            }
          ]
        },
        {
          id: 2,
          timestamp: '2022',
          content: [
            {
              id: 1,
              date: '1月',
              text: ['及猫云自研ASR语音平台上线，弥补了AI能力不足']
            },
            {
              id: 2,
              date: '3月',
              text: [
                '及猫云从外呼线索到SCRM私域沉淀，精确把握转化率，形成闭环体系'
              ]
            }
          ]
        },
        {
          id: 3,
          timestamp: '2021',
          content: [
            {
              id: 1,
              date: '3月',
              text: ['AI Vovice Bot外呼系统上线']
            }
          ]
        },
        {
          id: 4,
          timestamp: '2020',
          content: [
            {
              id: 1,
              date: '4月',
              text: ['及猫云外呼上线，布局5G消息生态系统']
            }
          ]
        },
        {
          id: 5,
          timestamp: '2019',
          content: [
            {
              id: 1,
              date: '2月',
              text: ['及猫云品牌成立，致力于为企业提供Saas一体化的智能解决方案']
            },
            {
              id: 2,
              date: '9月',
              text: ['希奥信息Paas系统上线']
            }
          ]
        },
        {
          id: 6,
          timestamp: '2014',
          content: [
            {
              id: 1,
              date: '2月',
              text: ['希奥信息挂牌“新三板” 证券代码：430632']
            }
          ]
        },

        {
          id: 7,
          timestamp: '2006',
          content: [
            {
              id: 1,
              date: '9月',
              text: ['希奥信息成立']
            }
          ]
        }
      ],
      honorList: [
        {
          id: 1,
          img: require('../../assets/img/aboutUs/information_security@2x.png'),
          text: '获得隐私信息管理体系认证证书'
        },
        {
          id: 2,
          img: require('../../assets/img/aboutUs/intellectual_property@2x.png'),
          text: '获得知识产权管理体系认证证书'
        },
        {
          id: 3,
          img: require('../../assets/img/aboutUs/cup@2x.png'),
          text: '获得IT互联网产品创新奖'
        }
      ]
    }
  },
  methods: {
    tabActives (id) {
      this.activeId = id
      this.activeObject = this.activities.filter(item => item.id === id)[0]
    }
  },
  mounted () {
    this.tabActives(1)
  }
}
</script>

<style lang="scss" scoped>
.company-about {
  width: 100%;
  .company-about-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #ffffff;
    }
  }
  .company-about-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 747px;
    background-image: url("../../assets/img/backImg@2x.png");
    background-size: 100% 100%;
    padding-left: 200px;
    padding-right: 63px;
    padding-bottom: 130px;
    .company-about-head-left {
      .company-about-head-title {
        display: flex;
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -4px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #ebca57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #ffffff;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 60px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #071b3c;
          z-index: 1;
          margin-left: -32px;
        }
      }
      .company-about-head-content {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #071b3c;
        line-height: 68px;
        margin: 33px 0 30px 0;
      }
    }
    .head-right-img {
      width: 563px;
      height: 547px;
      margin-right: 227px;
    }
  }
  .company-about-brief {
    display: flex;
    flex-direction: row;
    margin-top: 163px;
    padding-bottom: 181px;
    align-items: center;
    .brief-left {
      margin-left: 152px;
      width: 39%;
      .brief-left-title {
        .title {
          font-size: 43px;
          font-family: SourceHanSansCN;
          font-weight: bold;
          color: #000000;
        }
        .line {
          width: 184px;
          height: 13px;
          background: #ffe50c;
          border-radius: 10px;
          margin-top: -17px;
          margin-left: -6px;
        }
      }
      .brief-left-tips {
        font-size: 28px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top: 99px;
        margin-bottom: 25px;
      }
      .brief-left-content {
        font-size: 22px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        .item {
          margin-bottom: 7px;
          line-height: 35px;
        }
      }
    }
    .brief-right {
      width: 552px;
      height: 379px;
      margin-left: 203px;
      background-image: url("../../assets/img/aboutUs/edifice@2x.png");
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 80px;
      .brief-right-title {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        // margin-top: 140px;
        margin-bottom: 38px;
      }
      .brief-right-content {
        display: flex;
        align-items: center;
        .line {
          width: 25px;
          height: 1px;
          background: #ffffff;
        }
        .text {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          margin: 0 25px;
        }
      }
    }
  }
  .company-about-culture {
    width: 100%;
    background: #f8f8f8;
    padding-top: 84px;
    padding-bottom: 113px;
    .culture-title {
      margin-left: 160px;
      .title {
        font-size: 43px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
      .line {
        width: 184px;
        height: 13px;
        background: #ffe50c;
        border-radius: 10px;
        margin-top: -17px;
        margin-left: -6px;
      }
    }
    .culture-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 106px;
      margin-left: 231px;
      .item {
        width: 677px;
        height: 299px;
        background: #ffffff;
        margin-right: 53px;
        margin-bottom: 49px;
        padding-left: 81px;
        border-radius: 30px;
        .item-title {
          display: flex;
          flex-direction: row;
          margin-top: 35px;
          .title {
            font-size: 73px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #333333;
            z-index: 1;
          }
          .round {
            width: 40px;
            height: 40px;
            background: #ffe50c;
            border-radius: 50%;
            margin-left: -37px;
            margin-top: 8px;
          }
        }
        .item-tips {
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-top: 30px;
          margin-bottom: 20px;
        }
        .item-content {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }
  .company-about-development-process {
    .development-process-title {
      margin-top: 127px;
      margin-left: 94px;
      .title {
        font-size: 43px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
      .line {
        width: 184px;
        height: 13px;
        background: #ffe50c;
        border-radius: 10px;
        margin-top: -17px;
        margin-left: -6px;
      }
    }
    .development-process-content {
      display: flex;
      flex-direction: row;
      padding-bottom: 10px;
      .content-left {
        margin-left: 169px;
        margin-top: 149px;
        .item {
          display: flex;
          flex-direction: row;
          .title {
            font-size: 35px;
            font-family: PingFang SC;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.11);
            margin-right: 55px;
            width: 90px;
          }
          .title.active {
            color: #000000;
          }
          .round-line {
            margin-top: 12px;
            margin-bottom: -10px;
            .round-big {
              width: 20px;
              height: 20px;
              background: rgba(254, 212, 1, 0.2);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: -8px;
              .round-little {
                width: 13px;
                height: 14px;
                background: #ffd400;
                border-radius: 50%;
              }
            }
            .line {
              width: 4px;
              height: 76px;
              background: #fed301;
              border-radius: 2px;
            }
          }
          .round-line.active {
            .round-big {
              width: 31px;
              height: 31px;
              margin-left: -13px;
              .round-little {
                width: 24px;
                height: 24px;
              }
              position: relative;
              .position-line {
                position: absolute;
                left: 15px;
                top: -25px;
                width: 100px;
                height: 40px;
                border-bottom: 1px solid rgba(255, 215, 20, 0.4);
                border-left: 1px solid rgba(255, 215, 20, 0.4);
              }
            }
          }
        }
      }
      .content-right {
        margin-left: 146px;
        margin-top: 131px;
        .title {
          font-size: 57px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #000000;
        }
        .item {
          display: flex;
          flex-direction: row;
          align-items: center;
          .left-trigonum {
            width: 0;
            height: 0;
            border-left: 23px solid #ffd400;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            margin-right: 12px;
            margin-top: 128px;
          }
          .right-content {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            .date {
              margin-top: 71px;
              margin-bottom: 23px;
            }
            .text {
              line-height: 40px;
            }
          }
        }
      }
    }
  }
  .company-about-honor {
    padding-top: 136px;
    padding-bottom: 137px;
    background: #f8f8f8;
    .company-about-honor-head {
      margin-left: 153px;
      .title {
        font-size: 43px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }
      .line {
        width: 184px;
        height: 13px;
        background: #ffe50c;
        border-radius: 7px;
        margin-top: -18px;
        margin-left: -6px;
      }
    }
    .company-about-honor-content {
      display: flex;
      margin-top: 162px;
      margin-left: 280px;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 41px;
        width: 400px;
        height: 551px;
        background: #ffffff;
        border-radius: 30px;
        img {
          width: 400px;
          height: 472px;
          margin-bottom: 28px;
        }
        .text {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }
  .company-about-contact-us {
    .contact-us-head {
      padding-top: 67px;
      padding-left: 165px;
      height: 160px;
      background: #ffffff;
      .contact-us-title {
        font-size: 43px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }
      .contact-us-line {
        width: 184px;
        height: 13px;
        background: #ffe50c;
        border-radius: 7px;
        margin-top: -18px;
        margin-left: -6px;
      }
    }
    .contact-us-content {
      display: flex;
      margin-left: 331px;
      margin-top: 40px;
      .item {
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        width: 588px;
        height: 277px;
        margin-right: 54px;
        box-shadow: 0px 0px 177px 0px rgba(0, 0, 0, 0.05);
        border-radius: 30px;
        .left {
          width: 5px;
          height: 84px;
          background: #ffe50c;
          border-radius: 3px;
          margin-top: 100px;
        }
        .right {
          margin-left: 46px;
          margin-right: 114px;
          .right-tit {
            font-size: 33px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #333333;
            margin-top: 45px;
            margin-bottom: 36px;
          }
          .text {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            margin-bottom: 6px;
          }
          .phone {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-top: 30px;
          }
        }
      }
    }
    .contact-us-address {
      margin-top: 168px;
      margin-left: 331px;
      img {
        width: 1269px;
        height: 374px;
      }
      .contact-us-address-text {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #333333;
        margin-top: 36px;
      }
    }
  }
}
</style>
