<template>
  <div class="big-beitong">
    <web-header />
    <el-carousel
      :autoplay="false"
      class="big-beitong-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="big-beitong-head">
          <div class="big-beitong-head-left">
            <div class="big-beitong-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">掘贝通</div>
            </div>
            <div class="big-beitong-head-content">
              依托平台3亿+的企业数据覆盖能力，采集全网数据源进行分析挖掘及可视化展示，
              销售可根据多维度检索引擎、全方位客户画像、精准快速了解目标客户全貌，做到高效获客，提升效率。
            </div>
            <div class="big-beitong-btn">立即体验</div>
          </div>
          <img
            src="../assets/img/beitong/show_img@2x.png"
            alt="首页电视图"
            class="big-beitong-right-img"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="big-beitong-services">
      <div class="services-title">
        <div class="services-round" />
        <div class="services-tit">业务痛点</div>
      </div>
      <div class="serveices-content-back">
        <img
          src="../assets/img/back-left@2x.png"
          alt
          class="serveices-content-left-img"
        />
        <img
          src="../assets/img/back_right@2x.png"
          alt
          class="serveices-content-right-img"
        />
        <div class="serveices-content">
          <div
            class="services-content-item"
            v-for="item in servicesData"
            :key="item.id"
            :style="{ background: item.back }"
          >
            <img
              :src="item.img"
              alt
              class="item-left-img"
              :style="{ width: item.imgWidth, height: item.imgHeight }"
            />
            <div class="item-right">
              <div class="ite-right-tit">{{ item.tit }}</div>
              <div class="ite-right-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <left-down-content :data="leftDownData" />
    <right-top-content :data="rightTopData" />
    <middle-top-content :data="middleTopData" />
    <right-top-content :data="rightTopBackData" />
    <div class="big-beitong-help">
      <div class="help-title">
        <div class="round" />
        <div class="title">掘贝通 助力企业实现销售增长</div>
      </div>
      <div class="help-content">
        <div
          class="item"
          v-for="item in helpData"
          :key="item.id"
        >
          <img
            :src="item.img"
            :style="{
              width: item.imgWidth,
              height: item.imgHeight
            }"
          />
          <div class="item-title">{{ item.tit }}</div>
          <div
            class="item-content"
            :style="{ width: item.contWidth }"
          >{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="big-beitong-footer">
      <web-footer />
    </div>
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import leftDownContent from '../components/LeftDownContent/index.vue'
import rightTopContent from '../components/RightTopContent/index.vue'
import middleTopContent from '../components/MiddleTopContent/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'big-beitong',
  components: {
    webHeader,
    leftDownContent,
    rightTopContent,
    middleTopContent,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      servicesData: [
        {
          id: 1,
          img: require('../assets/img/beitong/获客@2x.png'),
          tit: '获客渠道少',
          content: '海量信息碎片化，企业获客渠道单一，无法精准找到适合自己的潜在客户',
          imgWidth: '44px',
          imgHight: '46px',
          back: '#FFFFFF'
        },
        {
          id: 2,
          img: require('../assets/img/beitong/质量成本@2x.png'),
          tit: '准找到适合自己的潜在客户',
          content: '行业竞争大，流量的获取越来越贵，推广的成本性价比不高，业绩效果不理想，获客成本增加',
          imgWidth: '42px',
          imgHight: '46px',
          back: '#FFE50C'
        },
        {
          id: 3,
          img: require('../assets/img/beitong/i-睡眠效率@2x.png'),
          tit: '销售效率低',
          content: '销售人员需要花费大量时间去寻找客户，还要维护有意向客户，费时费力，工作投入产出比低',
          imgWidth: '50px',
          imgHight: '42px',
          back: '#FFE50C'
        },
        {
          id: 4,
          img: require('../assets/img/beitong/KHCFDC_业绩@2x.png'),
          tit: '业绩难增长',
          content: '缺少专业的获客方式，无法持续获取精准优质客户，客户资源不稳定，业绩也不稳定',
          imgWidth: '55px',
          imgHight: '51px',
          back: '#FFFFFF'
        }
      ],
      leftDownData: {
        title: '智能搜索引擎 1秒找到优质客户率',
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '智能搜索引擎 1秒找到优质客户率'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '销售可根据多维度检索引擎、全方位客户画像、精准快速'
              },
              {
                id: 2,
                img: false,
                detail: '了解目标客户全貌，做到高效获客，提升效率。'
              }
            ]
          }
        ]
      },
      rightTopData: {
        title: '海量线索每日更新 智能挖掘优质项目商机',
        marginLeft: '40px',
        marginRight: '96px',
        marginTop: '10px',
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '基于大数据运算，每日有新增企业将会更新在首页'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '自动推送给销售，帮助企业持续获取精准客户，满'
              },
              {
                id: 2,
                img: false,
                detail: '足企业的找客需求'
              }
            ]
          }
        ]
      },
      middleTopData: {
        title: '覆盖全网有效数据 增加挖客新渠道作',
        isImg: false,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '挖客宝全国采集数据，均来自企业公开商业信息池，例如'
              },
              {
                id: 2,
                img: false,
                detail: '企业年报、招聘咨询、工商信息、百科内容等，汇聚全网营销线索'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '精准关联企业，全方位了解企业的经营动态及业务需求，数'
              },
              {
                id: 2,
                img: false,
                detail: '据安全、可靠、有保障。'
              }
            ]
          }
        ]
      },
      rightTopBackData: {
        title: '地图搜索精准定位 获取更多商机',
        marginRight: '96px',
        contMarginLeft: '60px',
        marginTop: '43px',
        isImg: true,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '精准定位企业地理位置，获取更多的周边商机'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '销售可通过地图搜索，批量定位附近目标客户，'
              },
              {
                id: 2,
                img: false,
                detail: '扩大拜访客户群，降低拜访成本，提高获客效率'
              }
            ]
          }
        ]
      },
      helpData: [
        {
          id: 1,
          img: require('../assets/img/beitong/intellect@2x.png'),
          // imgWidth: '73px',
          // imgHeight: '75px',
          tit: '系统智能化',
          content: '基于云计算、大数据、AI技术 等新一代信息技术的创新应用'
          // contWidth: '300px'
        },
        {
          id: 2,
          img: require('../assets/img/beitong/information@2x.png'),
          // imgWidth: '67px',
          // imgHeight: '76px',
          tit: '信息数据化',
          content: '覆盖全国不同类型行业，3亿+ 企业公开商业信息'
          // contWidth: '302px'
        },
        {
          id: 3,
          img: require('../assets/img/beitong/cost@2x.png'),
          // imgWidth: '83px',
          // imgHeight: '75px',
          tit: '高性价比',
          content: '节省人力成本、时间成本，满 足企业多样性的获客需求'
          // contWidth: '286px'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.big-beitong {
  width: 100%;
  background: #F8F8F8;
  .big-beitong-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .big-beitong-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 746px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-bottom: 150px;
    .big-beitong-head-left {
      font-family: SourceHanSansCN;
      color: #071B3C;
      margin-left: 86px;
      .big-beitong-head-title {
        font-size: 60px;
        font-weight: 800;
        display: flex;
        flex-direction: row;
        .title {
          z-index: 1;
          margin-left: -25px;
        }
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -6px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
         .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
      }
      .big-beitong-head-content {
        font-size: 24px;
        font-weight: 400;
        margin-top: 54px;
        width: 790px;
      }
      .big-beitong-btn {
        font-size: 22px;
        font-family: DOUYU;
        font-weight: 400;
        color: #FFE50C;
        width: 205px;
        background: #262626;
        border-radius: 31px;
        text-align: center;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-top: 95px;
      }
    }
    .big-beitong-right-img {
      width: 669px;
      height: 365px;
      margin-right: 167px;
    }
  }
  .big-beitong-services {
    margin-top: 160px;
    padding-bottom: 144px;
    .services-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 64px;
      margin-bottom: 159px;
      .services-round {
        width: 25px;
        height: 25px;
        background: #FFD400;
        border-radius: 50%;
        margin: 16px -20px 0 0;
      }
      .services-tit {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
    }
    .serveices-content-back {
      width: 100%;
      height: 590px;
      position: relative;
      display: flex;
      flex-direction: column;
      .serveices-content-left-img {
        width: 212px;
        height: 300px;
      }
      .serveices-content-right-img {
        width: 241px;
        height: 300px;
        align-self: flex-end;
        margin-top: 5px;
      }
      .serveices-content {
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 139px;
        display: flex;
        flex-wrap: wrap;
        .services-content-item {
          width: 776px;
          height: 245px;
          border: 4px solid #000000;
          border-radius: 30px;
          margin-right: 82px;
          margin-bottom: 55px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 45px;
          .item-left-img {
            margin-left: 58px;
            margin-right: 51px;
          }
          .item-right {
            color: #000000;
            .ite-right-tit {
              font-size: 30px;
              font-family: SourceHanSansCN;
              margin-bottom: 31px;
              font-weight: bold;
            }
            .ite-right-content {
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .big-beitong-help {
    width: 100%;
    height: 941px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    .help-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 190px;
      margin-bottom: 193px;
      .round {
        width: 25px;
        height: 25px;
        background: #FFD400;
        border-radius: 50%;
        margin-right: -16px;
        margin-top: 8px;
      }
      .title {
        font-size: 45px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
      }
    }
    .help-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 210px;
      .item {
        margin-right: 167px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 73px;
          height: 75px;
        }
        .item-title {
          font-size: 26px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
          margin-top: 25px;
          margin-bottom: 36px;
        }
        .item-content {
          font-size: 24px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #000000;
          width: 325px;
        }
      }
    }
  }
  .big-beitong-footer {
    width: 100%;
  }
}
</style>
