<template>
  <div class="need-battle">
    <div class="need-battle-title">
      <div class="round" />
      <div class="title">业务需求和挑战</div>
    </div>
    <div class="need-battle-content">
      <div
        class="item"
        v-for="item in data"
        :key="item.id"
      >
        <img :src="item.img" :class="item.imgName">
        <div
          v-for="(ite, ind) in item.text"
          :key="ind"
          :class="'text' + item.imgName"
        >{{ ite }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'need-battle',
  props: ['data']
}
</script>

<style lang="scss" scoped>
.need-battle {
  width: 100%;
  margin-top: 108px;
  padding-bottom: 156px;
  .need-battle-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 148px;
    .round {
      width: 25px;
      height: 25px;
      background: #FFD400;
      border-radius: 50%;
      margin-right: -20px;
    }
    .title {
      font-size: 43px;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #000000;
    }
  }
  .need-battle-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 380px;
    .item {
      width: 360px;
      height: 355px;
      background: #FFFFFF;
      border-radius: 30px;
      margin-right: 36px;
      img {
        margin-top: 73px;
      }
      .online-one {
        width: 91px;
        height: 78px;
        margin-left: 127px;
        margin-bottom: 58px;
        margin-top: 73px;
      }
      .online-two {
        width: 84px;
        height: 81px;
        margin-left: 138px;
        margin-bottom: 80px;
        margin-top: 71px;
      }
      .online-three {
        width: 77px;
        height: 75px;
        margin-left: 148px;
        margin-bottom: 72px;
        margin-top: 82px;
      }
      .finance-one {
        width: 64px;
        height: 80px;
        margin-left: 146px;
        margin-bottom: 86px;
        margin-top: 73px;
      }
      .finance-two {
        width: 61px;
        height: 79px;
        margin-left: 147px;
        margin-bottom: 82px;
        margin-top: 74px;
      }
      .finance-three {
        width: 64px;
        height: 80px;
        margin-left: 142px;
        margin-bottom: 90px;
        margin-top: 71px;
      }
      .education-one {
        width: 172px;
        height: 128px;
        margin-left: 99px;
        margin-bottom: 33px;
        margin-top: 55px;
      }
      .education-two {
        width: 103px;
        height: 107px;
        margin-left: 128px;
        margin-bottom: 51px;
        margin-top: 53px;
      }
      .education-three {
        width: 115px;
        height: 93px;
        margin-left: 118px;
        margin-bottom: 51px;
        margin-top: 60px;
      }
      div {
        font-size: 22px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #000000;
      }
      .textonline-one {
        margin-left: 38px;
      }
      .textonline-two {
        margin-left: 52px;
      }
      .textonline-three {
        margin-left: 32px;
      }
      .textfinance-one {
        margin-left: 45px;
      }
      .textfinance-two {
        margin-left: 53px;
      }
      .textfinance-three {
        margin-left: 44px;
      }
      .texteducation-one {
        margin-left: 40px;
      }
      .texteducation-two {
        margin-left: 40px;
      }
      .texteducation-three {
        margin-left: 38px;
      }
    }
  }
}
</style>
