<template>
  <div class="about-us">
    <web-header />
    <company-about />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import companyAbout from '../components/CompanyAnout/index.vue'

export default {
  name: 'about-us',
  components: {
    webHeader,
    companyAbout
  }
}
</script>

<style lang="scss" scoped>
.about-us {
  width: 100%;
}
</style>
