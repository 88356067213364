<template>
  <div class="product-support">
    <web-header />
    <el-carousel
      :autoplay="false"
      class="product-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="product-head">
          <div class="product-head-left">
            <div class="product-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">产品支持</div>
            </div>
            <div class="product-head-content">不同线路报备模板要求、及猫云企微助手操作手册等</div>
          </div>
          <img
            src="../assets/img/product/product_support@2x.png"
            alt="首页电视图"
            class="product-right-img"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="product-support-tab">
      <div class="tab-head">
        <div
          :class="item.id === activeId ? 'tab-item active' :' tab-item'"
          v-for="item in tabData"
          :key="item.id"
        >
          <div @click="tabClick(item.id)">{{ item.title }}</div>
          <div :class=" item.id === activeId ? 'tab-line-trigonum down' : 'tab-line-div'" />
        </div>
      </div>
      <div class="tab-content">
        <div class="tab-content-tit">及猫云</div>
        <div class="tab-content-img">
          <div
            class="tab-content-all"
            v-for="(item, index) in 3"
            :key="index"
          >
            <div class="tab-content-dialog">
              <img src="../assets/img/product/播放 (2)@2x.png" alt />
              <div class="title">如何降低加微成本?</div>
            </div>
          </div>
        </div>
        <div class="tab-content-text">及猫云相关帮助</div>
      </div>
    </div>
    <div class="product-support-footer">
      <web-footer />
    </div>
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'product-support',
  components: {
    webHeader,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      activeId: 1,
      tabData: [
        {
          id: 1,
          title: '新手入门'
        },
        {
          id: 2,
          title: '使用指南'
        },
        {
          id: 3,
          title: '常见问题'
        }
      ]
    }
  },
  methods: {
    tabClick (tab) {
      this.activeId = tab
    }
  }
}
</script>

<style lang="scss" scoped>
.product-support {
  width: 100%;
  .product-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .product-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 746px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-bottom: 150px;
    .product-head-left {
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #071B3C;
      margin-left: 86px;
      margin-top: -20px;
      .product-head-title {
        font-size: 65px;
        display: flex;
        flex-direction: row;
        .title {
          z-index: 1;
          margin-left: -32px;
        }
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -13px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
         .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
      }
      .product-head-content {
        font-size: 30px;
        margin-top: 62px;
      }
    }
    .product-right-img {
      width: 679px;
      height: 503px;
      margin-right: 177px;
    }
  }
  .product-support-tab {
    margin-top: 200px;
    .tab-head {
      display: flex;
      border-bottom: 2px solid #A3ACB4;
      margin: 0 110px;
      .tab-item {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-right: 99px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .tab-item.active {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFCE00;
      }
      .tab-line-div {
        position: relative;
        width: 202px;
        margin-top: 33px;
        float: left;
        margin-bottom: -2px;
      }
      .tab-line-trigonum {
        position: relative;
        border-top: 2px solid #FFD858;
        width: 202px;
        margin-top: 33px;
        float: left;
        margin-bottom: -2px;
      }
      .tab-line-trigonum:before,
      .tab-line-trigonum:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border: solid transparent;
        pointer-events: none;
        content: "";
      }
      .tab-line-trigonum.down:before {
        border-bottom-color: #FFD858;
        border-width: 12px;
        left: 49%;
        margin-left: -11px;
        bottom: 100%;
      }
      .tab-line-trigonum.down:after {
        border-bottom-color: #fff;
        border-width: 8px;
        left: 51%;
        margin-left: -11px;
        bottom: 100%;
      }
    }
    .tab-content {
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      height: 668px;
      background: rgba(255,244,153,0.07);
      border: 2px solid #000000;
      border-radius: 10px;
      margin: 55px 108px 0 98px;
      padding-left: 48px;
      .tab-content-tit {
        font-size: 30px;
        margin: 46px 0 41px 0;
      }
      .tab-content-img {
        display: flex;
        .tab-content-all {
          width: 461px;
          height: 367px;
          background: url('../assets/img/home/数据展示little@2x.png');
          background-size: 100% 100%;
          border-radius: 10px;
          position: relative;
          margin-right: 43px;
          .tab-content-dialog {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.62);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            img {
              width: 77px;
              height: 77px;
              margin-bottom: 39px;
            }
            .title {
              font-size: 26px;
              font-family: SourceHanSansCN;
              font-weight: bold;
              color: #FFFFFF;
            }
          }
        }
      }
      .tab-content-text {
        font-size: 26px;
        margin-top: 87px;
      }
    }
  }
  .product-support-footer {
    margin-top: 139px;
  }
}
</style>
