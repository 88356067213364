<template>
  <div class="news-events">
    <web-header />
    <el-carousel
      :autoplay="false"
      class="news-events-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="news-events-head">
          <div class="news-events-head-left">
            <div class="news-events-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">及猫云呼叫系统</div>
            </div>
            <div class="news-events-head-content">助力全流程销售，一站式服务</div>
            <div class="news-events-head-detail-content">智能化客户管理与销售管理，高效触达客户，降本增效解决企业电销难题，提升企业业绩，助力传统企业数字化转型。</div>
          </div>
          <div class="news-events-head-right">
            <img
              src="../assets/img/home/头部展示@2x.png"
              alt="首页电视图"
              class="show-img"
            >
            <img
              src="../assets/img/home/播放@2x.png"
              alt="首页电视图"
              class="open-img"
            >
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="news-events-tab-list">
      <div class="tab-content">
        <div
          class="item"
          v-for="item in tabData"
          :key="item.id"
        >
          <img class="img" :src="item.img" />
          <div class="right-content">
            <div class="title">{{ item.title }}</div>
            <div class="content">
              <div
                class="content-list"
                v-for="(ite, ind) in item.content"
                :key="ind"
              >{{ ite }}</div>
            </div>
            <div :class="item.cssName">
              <div class="foot-date">2022年4月14日</div>
              <div class="foot-look">查看全文》</div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-list">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="30"
        >
        </el-pagination>
      </div>
    </div>
    <div class="news-events-video">
      <!-- <video-player
          class="video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          @play="onPlayerPlay($event)"
        ></video-player> -->
      <div class="item-video">
        <img src="../assets/img/product/播放 (2)@2x.png" @click="playerPlay('video1')" >
        <video
          ref="video"
          id="video1"
          src="../assets/img/home/video(16).mp4"
        />
      </div>
      <div class="item-video">
        <img src="../assets/img/product/播放 (2)@2x.png" @click="playerPlay('video2')" >
        <video
          ref="video"
          id="video2"
          src="../assets/img/home/video(16).mp4"
        />
      </div>
      <div class="item-video">
        <img src="../assets/img/product/播放 (2)@2x.png" @click="playerPlay('video3')" >
        <video
          ref="video"
          id="video3"
          src="../assets/img/home/video(16).mp4"
        />
      </div>
    </div>
    <web-footer />
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'news-events',
  components: {
    webHeader,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      tabData: [
        {
          id: 1,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: '企业必看！居家办公如何高效提升销售业绩？',
          content: [
            '从获客到筛客再到私域打造与管理，及猫云帮助企业打造了一个闭',
            '环运营的营销方案，让你足不出户，也能做好业绩。'
          ],
          cssName: 'right-foot company-one'
        },
        {
          id: 2,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: '赋能企业 成为第一及猫云获客神器挖客宝上线！',
          content: [
            '问题来了，要想打造自己的私域流量，就得引流公域流量，在“流量”与',
            '“留量”之间，如何低成本获客，形成业务闭环，完成有效成交呢？针对',
            '这一企业需求 及猫云上线了一款简单、高效的获客产品——挖客宝，帮',
            '助企业能够在公域流量中......'
          ],
          cssName: 'right-foot company-two'
        },
        {
          id: 3,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: '及猫云智能机器人免费助力社区街道“科技”抗疫',
          content: [
            '为提高排查效率，减轻社区工作人员回访的任务负担，实现早发现，早预防，',
            '减居家期间感染的发生，上海希奥信息科技股份公司愿意为社区街道及相关',
            '政府部门，免费提供旗下的及猫云智能机器人GMAI……'
          ],
          cssName: 'right-foot company-three'
        },
        {
          id: 4,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: '及猫云牵手企业微信打造企业私域流量池',
          content: [
            '如何降低加微成本，提升加微效率？及猫云提出了“及猫云外呼系统邀约',
            '+主动加粉”打造私域流量池解决方案，实现高效沟通，快速积累粉丝。'
          ],
          cssName: 'right-foot company-four'
        },
        {
          id: 5,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: '关于我一口气做了这张长图，只为了……',
          content: [
            '你还记得吗？第一次发现隔行如隔山的一脸蒙蔽',
            '你还记得吗?第一次因似懂非懂而吃亏时落下的泪',
            '你还记得吗？每一次踩坑还不自知的时候……'
          ],
          cssName: 'right-foot company-five'
        }
      ],
      videolist: {
        // 自动播放
        autoplay: false,
        // 是否显示默认播放控件
        controls: 'controls'
      }
    }
  },
  methods: {
    playerPlay (id) {
      var elVideo = document.getElementById(id)
      elVideo.play()
    }
  }
}
</script>

<style lang="scss" scoped>
.news-events {
  width: 100%;
  .news-events-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .news-events-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 747px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-left: 90px;
    padding-right: 63px;
    padding-bottom: 150px;
    .news-events-head-left {
      .news-events-head-title {
        display: flex;
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -4px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 65px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #071B3C;
          z-index: 1;
          margin-left: -28px;
        }
      }
      .news-events-head-content {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #071B3C;
        line-height: 68px;
        margin: 63px 0 30px 0;
      }
      .news-events-head-detail-content {
        width: 729px;
      }
    }
    .news-events-head-right {
      position: relative;
      width: 850px;
      height: 482px;
      .show-img {
        width: 100%;
        height: 100%;
      }
      .open-img {
        position: absolute;
        top: 215px;
        left: 404px;
        width: 119px;
        height: 119px;
      }
    }
  }
  .news-events-tab-list {
    width: 100%;
    margin-top: 200px;
    .tab-content {
      .item {
        display: flex;
        flex-direction: row;
        background: #FAFAFA;
        margin-bottom: 44px;
        margin-left: 153px;
        margin-right: 174px;
        .img {
          width: 660px;
          height: 293px;
          margin-right: 48px;
          margin-top: 46px;
          margin-left: 63px;
          margin-bottom: 51px;
        }
        .right-content {
          margin-top: 66px;
          .title {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #000000;
            margin-bottom: 37px;
          }
          .content {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }
          .right-foot {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .foot-look {
              margin-left: 463px;
            }
          }
          .company-one {
            margin-top: 88px;
          }
          .company-two {
            margin-top: 34px;
          }
          .company-three {
            margin-top: 49px;
          }
          .company-four {
            margin-top: 110px;
          }
          .company-five {
            margin-top: 51px;
          }
        }
      }
    }
    .tab-list {
      display: flex;
      justify-content: center;
      margin-top: 71px;
      ::v-deep {
        .el-pagination.is-background {
          .el-pager li {
            min-width: 67px;
            height: 70px;
            padding-top: 18px;
            border: 2px solid #E9EBEE;
            border-radius: 10px;
            background: #FFFFFF;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }
          .el-pager li:not(.disabled).active {
            background: #FFE50C;
            border: none;
          }
          button {
            background: #FFFFFF;
            width: 67px;
            height: 70px;
            border: 2px solid #E9EBEE;
            border-radius: 10px;
            .el-icon {
              font-size: 31px;
            }
          }
          button:disabled {
            display: none;
          }
        }
      }
    }
  }
  .news-events-video {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 160px;
    margin-top: 230px;
    .item-video {
      position: relative;
      margin-right: 63px;
      img {
        position: absolute;
        top: 90px;
        left: 180px;
        width: 95px;
        height: 96px;
        z-index: 1;
      }
      video {
        width: 461px;
        height: 287px;
      }
    }
  }
}
</style>
