<template>
  <div class="message-information">
    <div class="message-information-head">
      <img src="../assets/img/及猫云logo@2x.png" @click="goBackHome" />
      <div class="line" />
      <div class="tip-content">全球AI通信能力开放平台</div>
    </div>
    <div class="message-information-content">
      <div class="message-information-left">
        <img src="../assets/img/information_show@2x.png" />
      </div>
      <div class="message-information-right">
        <div class="message-information-title">
          <div class="title">免费体验 DEMO</div>
          <div class="line" />
        </div>
        <div class="message-information-tip">
          输入手机号验证码即可进入虚拟账号体验功能
        </div>
        <el-form
          ref="ruleForm"
          class="demo-ruleForm"
          :model="ruleForm"
          :rules="rules"
        >
          <el-form-item prop="customName">
            <el-input
              v-model="ruleForm.customName"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item prop="customContact">
            <el-input
              v-model="ruleForm.customContact"
              type="number"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="code" class="demo-code">
            <el-input
              v-model="ruleForm.code"
              auto-complete="off"
              placeholder="验证码"
              maxlength="4"
            >
              <!-- <svg-icon
                slot="prefix"
                icon-class="validCode"
                class="el-input__icon input-icon"
              /> -->
            </el-input>

            <img class="login-code" :src="codeUrl" @click="getCode" />
          </el-form-item>

          <el-form-item>
            <!-- <div class="ruleFormBtn" @click="submitForm('ruleForm')">
              建设中...
            </div> -->
            <div class="ruleFormBtn" @click="submitForm">申请试用</div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<!-- <script src="../assets/gt4.js"></script> -->

<script>
export default {
  name: 'message-information',
  data () {
    return {
      ruleForm: {
        customName: '',
        customContact: this.$route.query.phone ? this.$route.query.phone : '',
        code: ''
      },
      rules: {
        code: [{ required: true, message: '验证码不能为空', trigger: 'blur' }],
        customName: [
          { required: true, message: '客户姓名不能为空', trigger: 'blur' },
          {
            max: 16,
            message: '客户姓名不能超过16个字符',
            trigger: ['blur', 'change']
          }
        ],
        customContact: [
          { required: true, message: '手机号不能为空', trigger: 'change' },
          { min: 8, max: 12, message: '长度在 8 到 12 个字符', trigger: 'blur' }
        ]
      },
      codeUrl: '',
      // 验证 id，极验后台申请得到
      captchaId: '10725f289bf4a25f4caef9f47931d525'
    }
  },
  mounted () {
    this.getCode()
  },
  methods: {
    submitForm: function () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          fetch('https://gmall.com.cn/ma/api/gmallApplyDemo', {
            // fetch('https://dev-aivo.paasw.com/ma/api/gmallApplyDemo', {
            method: 'POST',
            headers: {
              // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              customName: this.ruleForm.customName,
              customContact: this.ruleForm.customContact,
              uuid: this.ruleForm.uuid,
              code: this.ruleForm.code
            })
          })
            .then(res => res.json())
            .then(res => {
              console.log('申请试用', res)
              if (res.code == 200) {
                // this.$message.success('您已提交试用申请，请耐心等待业务员联系')
                this.$alert(
                  '您的申请已提交，请耐心等待，客户服务专员会尽快与您联系！',
                  '申请试用',
                  {
                    confirmButtonText: '确定',
                    callback: action => {
                      this.$message({
                        type: 'success',
                        message: '已关闭'
                      })
                    }
                  }
                )
                this.ruleForm = {
                  customName: '',
                  customContact: '',
                  code: ''
                }
              } else {
                this.$message.error(res.msg)
                this.getCode()
              }
            })
        }
      })
    },

    getCode () {
      fetch('https://gmall.com.cn/ma/api/captchaImage')
        // fetch('https://dev-aivo.paasw.com/ma/api/captchaImage')
        .then(res => res.json())
        .then(res => {
          console.log('res验证码', res)
          this.codeUrl = 'data:image/gif;base64,' + res.img
          this.ruleForm.uuid = res.uuid
        })
    },
    init () {
      fetch('https://dev-aivo.paasw.com/geetest/register')
        .then(res => res.json())
        .then(res => {
          console.log(res)
          window.initGeetest(
            {
              // 以下配置参数来自服务端 SDK
              gt: res.gt,
              challenge: res.challenge,
              offline: !res.success,
              new_captcha: true
            },
            function (captchaObj) {
              // 这里可以调用验证实例 captchaObj 的实例方法
            }
          )
          // initGeetest({
          //   gt: res.gt,
          //   challenge: res.challenge,
          //   new_captcha: res.new_captcha, // 用于宕机时表示是新验证码的宕机
          //   offline: !res.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
          //   product: 'float', // 产品形式，包括：float，popup
          //   width: '100%'
          // })
        })
    },
    goBackHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

.message-information {
  width: 100%;
  height: 100%;
  background: #f3f4f8;
  display: flex;
  flex-direction: column;
  align-items: center;

  .message-information-head {
    width: 100%;
    height: 100px;
    background: #f1df41;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 131px;
      height: 57px;
      margin-left: 110px;
      cursor: pointer;
    }
    .line {
      width: 1px;
      height: 44px;
      background: #071b3c;
      margin: 0 16px;
    }
    .tip-content {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #29343b;
      line-height: 25px;
    }
  }
  .message-information-content {
    display: flex;
    flex-direction: row;
    width: 1200px;
    // height: 738px;
    height: 780px;
    margin-top: 35px;
    background: #ffffff;
  }
  .message-information-left {
    width: 582px;
    // height: 738px;
    height: 780px;
    background: #f1df41;
    img {
      width: 497px;
      height: 502px;
      margin-top: 189px;
      margin-left: 38px;
    }
  }
  .message-information-right {
    margin-top: 155px;
    margin-left: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .message-information-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        margin-bottom: -14px;
        z-index: 1;
      }
      .line {
        width: 236px;
        height: 10px;
        background: #fce520;
        border-radius: 5px;
      }
    }
    .message-information-tip {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      margin-top: 56px;
      margin-bottom: 64px;
    }

    .demo-ruleForm {
      ::v-deep {
        .demo-code {
          display: flex;
          .el-form-item__content {
            width: 430px;
            display: flex;
            align-items: center;
            // justify-content: center;
            .el-input__inner {
              // width: 230px;
              width: 100%;
              height: 48px;
              border-radius: 30px;
              font-size: 18px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              margin-top: 10px;
              padding: 0 29px;
            }
            .login-code {
              width: 120px;
              height: 48px;
              margin-left: 8px;
              // background-color: rebeccapurple;
            }
          }
        }
        .el-input__inner {
          width: 430px;
          height: 48px;
          border-radius: 30px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          margin-top: 10px;
          padding: 0 29px;
        }
        .el-input__inner:focus {
          border: #fbe953 1px solid;
        }
        ::-webkit-input-placeholder {
          color: #999999;
          letter-spacing: 2px;
        }
      }
      .ruleFormBtn {
        width: 429px;
        height: 48px;
        background: #f6f6f6;
        border-radius: 24px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        letter-spacing: 2px;
      }
    }
  }
}
</style>
