<template>
  <div class="market-activity">
    <web-header />
    <el-carousel
      :autoplay="false"
      class="market-activity-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="market-activity-head">
          <div class="market-activity-head-left">
            <div class="market-activity-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">及猫云呼叫系统</div>
            </div>
            <div class="market-activity-head-content">助力全流程销售，一站式服务</div>
            <div class="market-activity-head-detail-content">智能化客户管理与销售管理，高效触达客户，降本增效解决企业电销难题，提升企业业绩，助力传统企业数字化转型。</div>
          </div>
          <div class="market-activity-head-right">
            <img
              src="../assets/img/home/头部展示@2x.png"
              alt="首页电视图"
              class="show-img"
            >
            <img
              src="../assets/img/home/播放@2x.png"
              alt="首页电视图"
              class="open-img"
            >
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="the-latest-activity">
      <div class="the-latest-activity-title">
        <div class="vertical" />
        <div class="title">最新活动</div>
      </div>
      <div class="the-latest-activity-title-english">The latest activity</div>
      <div class="the-latest-activity-content">
        <img
          class="content-left-img"
          src="../assets/img/home/数据展示little@2x.png"
        />
        <div class="content-right">
          <div class="right-title">补贴3000万！及猫云助力中小企业开展业务，领完为止！</div>
          <div class="right-tip">
            巨大的生存挑战。针对此次疫情，希奥信息推出“补贴政策”，助力中小企业
            开展业务，疫情期间互相携手，努力活下去！
          </div>
          <div class="right-detail-tit">补贴详情：</div>
          <div class="right-detail-content">
            <span>1 </span>
            <div>
              <div class="item">所有套餐用户，免费赠送及猫云挖客宝线索系统及私域打造的及猫云企业</div>
              <div class="item">微信小助手，每个ID最高补贴1200元/年 ；</div>
              <div class="item">所有新开的机器人系统用户，一律享受3折优惠</div>
            </div>
          </div>
          <div class="right-detail-content">
            <span>2 </span>
            <div>
              <div class="item">所有套餐用户，免费赠送及猫云挖客宝线索系统及私域打造的及猫云企业</div>
              <div class="item">微信小助手，每个ID最高补贴1200元/年 ；</div>
              <div class="item">所有新开的机器人系统用户，一律享受3折优惠</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="review-past">
      <div class="review-past-title">
        <div class="vertical" />
        <div class="title">往期回顾</div>
      </div>
      <div class="review-past-title-english">Review past</div>
      <div class="review-past-content">
        <div
          class="item"
          v-for="item in pagData"
          :key="item.id"
        >
          <img class="item-left-img" :src="item.img" />
          <div class="item-right">
            <div
              class="right-title"
              v-for="(ite, ind) in item.title"
              :key="ind"
            >{{ ite }}</div>
            <div class="right-content">
              <div
                class="content-item"
                v-for="(ite, ind) in item.content"
                :key="ind"
              >{{ ite }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="review-past-total">共10页 18条内容</div>
      <div class="review-past-pag">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="30"
        >
        </el-pagination>
      </div>
    </div>
    <web-footer />
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'market-activity',
  components: {
    webHeader,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      pagData: [
        {
          id: 1,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: ['我摊牌了，这个礼包快抢完了……'],
          content: [
            '及猫云为了助力更多的企业伙伴抢占先机，打响新春第一战 2月推出了新春',
            '优惠活动，带来多种优惠套餐，让企业用更低的成本使用上优质、稳定的外',
            '呼系统。'
          ]
        },
        {
          id: 2,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: [
            '及猫云年终狂欢盛典！送分钟数、送系统费，更有',
            '直播间彩蛋！'
          ],
          content: [
            '对于很多消费者来说，提到购物节仿佛还在被各种满减、定金等规则带来的恐惧',
            '感支配着，翻阅各种购物攻略 最后都给整emo了，最后的最后，不买了，气！'
          ]
        },
        {
          id: 3,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: ['双十一特惠活动倒计时2天！充值满送香奈儿、新款'],
          content: [
            '及猫云为了助力更多的企业伙伴抢占先机，打响新春第一战 2月推出了新春',
            '优惠活动，带来多种优惠套餐，让企业用更低的成本使用上优质、稳定的外',
            '呼系统。'
          ]
        },
        {
          id: 4,
          img: require('../assets/img/home/数据展示little@2x.png'),
          title: [
            '及猫云年终狂欢盛典！送分钟数、送系统费，更有',
            '直播间彩蛋！'
          ],
          content: [
            '对于很多消费者来说，提到购物节仿佛还在被各种满减、定金等规则带来的恐惧',
            '感支配着，翻阅各种购物攻略 最后都给整emo了，最后的最后，不买了，气！'
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.market-activity {
  width: 100%;
  background: #FFFFFF;
  .market-activity-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .market-activity-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 747px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-left: 90px;
    padding-right: 63px;
    padding-bottom: 150px;
    .market-activity-head-left {
      .market-activity-head-title {
        display: flex;
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -4px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 65px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #071B3C;
          z-index: 1;
          margin-left: -32px;
        }
      }
      .market-activity-head-content {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #071B3C;
        line-height: 68px;
        margin: 63px 0 30px 0;
      }
      .market-activity-head-detail-content {
        width: 729px;
      }
    }
    .market-activity-head-right {
      position: relative;
      width: 850px;
      height: 482px;
      .show-img {
        width: 100%;
        height: 100%;
      }
      .open-img {
        position: absolute;
        top: 215px;
        left: 404px;
        width: 119px;
        height: 119px;
      }
    }
  }
  .the-latest-activity {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 142px;
    .the-latest-activity-title {
      display: flex;
      flex-direction: row;
      margin-top: 90px;
      .vertical {
        width: 11px;
        height: 83px;
        background: #FFE50C;
        border-radius: 5px;
        margin-right: -15px;
        margin-top: -17px;
        z-index: 1;
      }
      .title {
        width: 243px;
        height: 79px;
        background: #0E0900;
        border-radius: 10px;
        font-size: 35px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFE50C;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .the-latest-activity-title-english {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(7, 27, 61, 0.56);
      margin-top: 24px;
      margin-bottom: 112px;
    }
    .the-latest-activity-content {
      display: flex;
      flex-direction: row;
      background: #FAFAFA;
      width: 100%;
      padding-bottom: 38px;
      .content-left-img {
        width: 660px;
        height: 453px;
        margin-left: 190px;
        margin-top: 160px;
        margin-right: 81px;
      }
      .content-right {
        color: #000000;
        .right-title {
          width: 680px;
          font-size: 32px;
          font-family: Source Han Sans CN;
          font-weight: 800;
          margin-top: 83px;
          margin-bottom: 49px;
        }
        .right-tip {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          width: 820px;
          line-height: 40px;
        }
        .right-detail-tit {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          margin-top: 33px;
          margin-bottom: 31px;
        }
        .right-detail-content {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          display: flex;
          flex-direction: row;
          margin-bottom: 1px;
          .item {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
  .review-past {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 160px;
    .review-past-title {
      display: flex;
      flex-direction: row;
      margin-top: 126px;
      .vertical {
        width: 11px;
        height: 83px;
        background: #FFE50C;
        border-radius: 5px;
        margin-right: -15px;
        margin-top: -17px;
        z-index: 1;
      }
      .title {
        width: 243px;
        height: 79px;
        background: #0E0900;
        border-radius: 10px;
        font-size: 35px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFE50C;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .review-past-title-english {
      font-size: 26px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(7, 27, 61, 0.56);
      margin-top: 34px;
      margin-bottom: 119px;
    }
    .review-past-content {
      width: 100%;
      background: #FAFAFA;
      padding-left: 189px;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 38px;
        height: 404px;
        .item-left-img {
          width: 660px;
          height: 283px;
          margin-right: 76px;
        }
        .item-right {
          .right-title {
            font-size: 36px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #000000;
          }
          .right-content {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
            margin-top: 36px;
            .content-item {}
          }
        }
      }
    }
    .review-past-total {
      width: 100%;
      text-align: right;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #000000;
      padding-top: 71px;
      padding-right: 109px;
      margin-top: -38px;
      // background: #ccc;
    }
    .review-past-pag {
      margin-top: 75px;
      ::v-deep {
        .el-pagination.is-background {
          .el-pager li {
            min-width: 67px;
            height: 70px;
            padding-top: 18px;
            border: 2px solid #E9EBEE;
            border-radius: 10px;
            background: #FFFFFF;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #000000;
          }
          .el-pager li:not(.disabled).active {
            background: #FFE50C;
            border: none;
          }
          button {
            background: #FFFFFF;
            width: 67px;
            height: 70px;
            border: 2px solid #E9EBEE;
            border-radius: 10px;
            .el-icon {
              font-size: 31px;
            }
          }
          button:disabled {
            display: none;
          }
        }
      }
    }
  }
}
</style>
