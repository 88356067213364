<template>
  <div
    :class="data.isImg ? 'right-top-content-back': 'right-top-content'"
    :style="data.isBack ? {background: '#FFFBEB'} : {}"
  >
    <div
      class="left-content"
      :style="{
        marginRight: data.marginRight,
        marginLeft: data.contMarginLeft ? data.contMarginLeft : '0px',
        marginTop: data.marginTop ? data.marginTop : '0px'
      }"
    >
      <div class="title" v-show="!data.isBack">{{ data.title }}</div>
      <div class="title" v-show="data.isBack">
        <div
          class="item-title"
          v-for="item in data.title"
          :key="item"
        >
          {{ item }}
        </div>
      </div>
      <div
        class="item"
        v-for="(item, index) in data.content"
        :key="index"
        :style="{ marginLeft: data.marginLeft ? data.marginLeft : '0px' }"
      >
        <div class="item-all" v-for="(ite) in item.text" :key="ite.id">
          <div class="item-all-img" v-if="ite.img">
            <img
              src="../../assets/img/round_right@2x.png"
              alt
            />
            <div class="item-content">{{ ite.detail }}</div>
          </div>
          <div class="item-all-text" v-if="!ite.img">{{ ite.detail }}</div>
        </div>
      </div>
    </div>
    <div class="right-content">
      <img
        src="../../assets/img/展示图数据公共@2x.png"
        alt
        class="left-img"
      />
      <img
        src="../../assets/img/oval@2x.png"
        alt
        class="right-img"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'right-top-content',
  props: ['data']
}
</script>

<style lang="scss" scoped>
.right-top-content {
  width: 100%;
  display: flex;
  height: 670px;
  padding-left: 167px;
  padding-top: 155px;
}
.right-top-content-back {
  background: url('../../assets/img/Shape_left@2x.png') no-repeat left bottom;
  background-size: 345px 367px;
  width: 100%;
  display: flex;
  height: 670px;
  padding-left: 167px;
  padding-top: 155px;
}
.left-content {
    color: #000000;
    .title {
      font-size: 34px;
      font-family: SourceHanSansCN;
      font-weight: bold;
      margin-bottom: 49px;
      .item-title {
        margin-bottom: 10px;
      }
    }
    .item {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 500;
      margin-bottom: 30px;
      width: 727px;
      .item-all {
        display: flex;
        flex-direction: column;
        .item-all-img {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 7px;
          }
        }
        .item-all-text {
          margin-bottom: 10px;
        }
      }
    }
  }
  .right-content {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    .left-img {
      width: 599.8px;
      height: 338.9px;
      z-index: 2;
    }
    .right-img {
      width: 106px;
      height: 117px;
      margin-left: -73px;
      margin-top: -44px;
      z-index: 1;
    }
  }
</style>
