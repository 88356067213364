<template>
  <div class="cooperation-agent">
    <web-header />
    <el-carousel
      :autoplay="false"
      class="cooperation-agent-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="cooperation-agent-head">
          <div class="cooperation-agent-head-left">
            <div class="cooperation-agent-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">智能融合通信一体化开放API引领者</div>
            </div>
            <div class="cooperation-agent-head-content">
              希望能够和合作伙伴一起，帮助更多的企业成功，实现数
              字化经营。同时，共同进步发展，实现更多的价值，共享
              万亿级市场红利
            </div>
          </div>
          <img
            src="../assets/img/home/多个盒子@2x.png"
            alt
            class="cooperation-agent-head-right"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="cooperation-agent-select">
      <div class="select-title">
        <div class="title">为什么选择及猫云</div>
        <div class="line" />
      </div>
      <div class="select-tab">
        <div class="tab-head">
          <div
            :class="item.id === activeId ? 'tab-item active' :' tab-item'"
            v-for="item in tabData"
            :key="item.id"
          >
            <div @click="tabClick(item.id)">{{ item.title }}</div>
            <div :class=" item.id === activeId ? 'tab-line-trigonum down' : 'tab-line-div'" />
          </div>
        </div>
        <div class="tab-content">
          <div
            class="item"
            v-for="item in tabContent"
            :key="item.id"
          >
            <div class="item-title">{{ item.title }}</div>
            <div class="item-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="cooperation-agent-market">
      <img
        class="market-left-img"
        src="../assets/img/home/数据展示little@2x.png"
      />
      <div class="market-right">
        <img
          class="before-img"
          src="../assets/img/cooperation/后引号@2x.png"
          alt
        >
        <div class="title-round">
          <div class="round" />
          <div class="title">庞大的市场红利</div>
        </div>
        <div class="content">
          2020年市场规模预计增长至253.4亿元，2021年更有望超过320亿元。艾
          媒咨询分析师认为，随着宏观环境变化，企业商业模式不断变迁，近年如
          短视频、直播电商等新兴渠道发展迅速，各行业也需要通过更专业的服务
          应对市场变化，也推动着SaaS行业市场不断扩大。
        </div>
        <img
          class="after-img"
          src="../assets/img/cooperation/前引号@2x.png"
          alt
        >
      </div>
    </div>
    <div class="cooperation-agent-cooperate">
      <div class="cooperate-title">
        <div class="round" />
        <div class="title">成为及猫云合作伙伴 合作共赢 共创辉煌</div>
      </div>
      <div class="cooperate-content">
        <div
          class="cooperate-content-item"
          v-for="item in cooperateData"
          :key="item.id"
        >
          <div
            :class="'item-' + item.imgName"
          >
            {{ item.btnCont }}
          </div>
          <div class="arrow" v-show="item.isArrow">
            <div class="arrow-line" />
            <div class="arrow-back" />
          </div>
        </div>
      </div>
      <div class="cooperate-btn">立即加入</div>
    </div>
    <div class="cooperation-agent-experience">
      <div class="experience-title">
        <div class="title">体验中心</div>
        <div class="line" />
      </div>
      <div class="experience-tip">
        及猫云是是专业的AI语音交互、企业大数据的智能通讯云服务商，为各企业提供领先的数字化能力，提高沟通体验
        和运营效率，为云服务释放增长潜力，驱动中国通讯行业实现互联网化、云计算化、融合化和智能化。
      </div>
      <div class="experience-content">
        <div
          class="item"
          v-for="item in experienceData"
          :key="item.id"
        >
          <div class="item-head">
            <img
              :src="item.img"
              :class="item.imgName"
              alt
            >
            <div class="item-title">{{ item.title }}</div>
          </div>
          <div class="item-tip">{{ item.tip }}</div>
          <div class="item-content">
            <div
              class="content-list"
              v-for="(ite, ind) in item.list"
              :key="ind"
            >
              <img src="../assets/img/round_right@2x.png" />
              <div class="list">{{ ite }}</div>
            </div>
          </div>
          <div class="item-btn">了解更多</div>
        </div>
      </div>
    </div>
    <web-footer />
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
//          :style="{backgroundImage: `url(${item.img})`,}"
import webHeader from '../components/WebHeader/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'
export default {
  name: 'cooperation-agent',
  components: {
    webHeader,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      activeId: 1,
      tabData: [
        {
          id: 1,
          title: '新手入门'
        },
        {
          id: 2,
          title: '使用指南'
        },
        {
          id: 3,
          title: '常见问题'
        }
      ],
      tabContent: [
        {
          id: 1,
          title: '品牌背书',
          content: '企业从成立至今有16年时间，服务有3w+企业客'
        },
        {
          id: 2,
          title: '产品、服务体系成熟',
          content: '及猫云拥有完善的产品研发、服务运营管理体系，自主研发的产品成熟且具有灵活性，产品规划和节奏感强，能高效解决客户核心需求，备受客户青睐完善的完善的'
        },
        {
          id: 3,
          title: '一流的核心团队',
          content: '拥有来自百度、华为、一号店等技术人才组成的核心团队，管理层平均拥有5~10年以上的企业级服务领域产品、运营经验。'
        }
      ],
      cooperateData: [
        {
          id: 1,
          img: require('../assets/img/cooperation/合作申请@2x.png'),
          imgName: 'apply-for',
          btnCont: '合作申请',
          isArrow: true
        },
        {
          id: 2,
          img: require('../assets/img/cooperation/合作洽谈@2x.png'),
          imgName: 'talk-over',
          btnCont: '合作洽谈',
          isArrow: true
        },
        {
          id: 3,
          img: require('../assets/img/cooperation/签订协议@2x.png'),
          imgName: 'treaty',
          btnCont: '签订协议',
          isArrow: true
        },
        {
          id: 4,
          img: require('../assets/img/cooperation/合作申请@2x.png'),
          imgName: 'cooperate-open',
          btnCont: '合作开启',
          isArrow: true
        },
        {
          id: 5,
          img: require('../assets/img/cooperation/合作共赢@2x.png'),
          imgName: 'all-win',
          btnCont: '合作共赢',
          isArrow: false
        }
      ],
      experienceData: [
        {
          id: 1,
          img: require('../assets/img/cooperation/掘贝通@2x.png'),
          imgName: 'beitong',
          title: '掘贝通',
          tip: '精准快速了解目标客户全貌',
          list: [
            '分析挖掘及可视化展示',
            '多维度检索引擎',
            '全方位客户画像',
            '精准快速了解目标客户全貌'
          ]
        },
        {
          id: 2,
          img: require('../assets/img/cooperation/机器人GMAI@2x.png'),
          imgName: 'gmai',
          title: '机器人GMAI',
          tip: '最大限度地提升企业电销效率',
          list: [
            '模拟真人声音与客户进行电话',
            '操作简单，自主呼叫',
            '毫秒级响应  全年无休',
            '适应多种场景化应用'
          ]
        },
        {
          id: 3,
          img: require('../assets/img/cooperation/呼叫系统@2x.png'),
          imgName: 'call',
          title: '及猫云呼叫系统',
          tip: '提升人工坐席，提高成单量',
          list: [
            '提供丰富线路 稳定通话',
            '客户资源管理 一站式解决',
            '高效触达客户',
            '一键拨号 全程录音 通话统计'
          ]
        },
        {
          id: 4,
          img: require('../assets/img/cooperation/助手@2x.png'),
          imgName: 'aide',
          title: '及猫云助手',
          tip: '打造私域流量 高效引流',
          list: [
            '挖掘客户价值 精华客户运营',
            '加速业务转化 提升企业业绩'
          ]
        }
      ]
    }
  },
  methods: {
    tabClick (tab) {
      this.activeId = tab
    }
  }
}
</script>

<style lang="scss" scoped>
.cooperation-agent {
  width: 100%;
  background: #FFFFFF;
  .cooperation-agent-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .cooperation-agent-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 747px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-left: 93px;
    padding-right: 132px;
    padding-bottom: 150px;
    .cooperation-agent-head-left {
      .cooperation-agent-head-title {
        display: flex;
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -13px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 50px;
          font-family: DOUYU;
          font-weight: 800;
          color: #071B3C;
          line-height: 93px;
          z-index: 1;
          margin-left: -32px;
        }
      }
      .cooperation-agent-head-content {
        font-size: 24px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #071B3C;
        // margin: 54px 0 30px 0;
        margin-top: 54px;
        width: 780px;
      }
    }
    .cooperation-agent-head-right {
      width: 610px;
      height: 460px;
    }
  }
  .cooperation-agent-select {
    margin-top: 132px;
    .select-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 82px;
      margin-bottom: 112px;
      .title {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
        z-index: 1;
      }
      .line {
        width: 380px;
        height: 13px;
        background: #FFE50C;
        border-radius: 10px;
        margin-top: -19px;
      }
    }
    .select-tab {
      padding-bottom: 204px;
      .tab-head {
        display: flex;
        border-bottom: 2px solid #A3ACB4;
        margin: 0 105px 55px 116px;
        .tab-item {
          font-size: 24px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          margin-right: 99px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .tab-item.active {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #FFCE00;
        }
        .tab-line-div {
          position: relative;
          width: 192px;
          margin-top: 33px;
          float: left;
          margin-bottom: -2px;
        }
        .tab-line-trigonum {
          position: relative;
          border-top: 2px solid #FFD858;
          width: 192px;
          margin-top: 33px;
          float: left;
          margin-bottom: -2px;
        }
        .tab-line-trigonum:before,
        .tab-line-trigonum:after {
          position: absolute;
          display: block;
          width: 0;
          height: 0;
          border: solid transparent;
          pointer-events: none;
          content: "";
        }
        .tab-line-trigonum.down:before {
          border-bottom-color: #FFD858;
          border-width: 12px;
          left: 49%;
          margin-left: -11px;
          bottom: 100%;
        }
        .tab-line-trigonum.down:after {
          border-bottom-color: #fff;
          border-width: 8px;
          left: 51%;
          margin-left: -11px;
          bottom: 100%;
        }
      }
      .tab-content {
        margin: 0 103px;
        height: 428px;
        background: rgba(255,244,153,0.07);
        border: 2px solid #000000;
        border-radius: 10px;
        padding-top: 43px;
        padding-left: 35px;
        .item {
          margin-bottom: 35px;
          .item-title {
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #333333;
            margin-bottom: 25px;
          }
          .item-content {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
  }
  .cooperation-agent-market {
    width: 100%;
    background: #F8F8F8;
    display: flex;
    flex-direction: row;
    .market-left-img {
      width: 778px;
      height: 448px;
      margin-left: 102px;
      margin-top: 199px;
      margin-right: 80px;
    }
    .market-right {
      display: flex;
      flex-direction: column;
      .before-img {
        width: 141px;
        height: 105px;
        margin-top: 54px;
        margin-bottom: 47px;
      }
      .after-img {
        width: 141px;
        height: 105px;
        margin-left: 650px;
        margin-bottom: 34px;
      }
      .title-round {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 100px;
        margin-top: 40px;
        .round {
          width: 40px;
          height: 40px;
          background: #FFE50C;
          border-radius: 50%;
          margin-right: -30px;
          margin-top: 2px;
        }
        .title {
          font-size: 45px;
          font-family: SourceHanSansCN;
          font-weight: bold;
          color: #000000;
        }
      }
      .content {
        width: 590px;
        margin-left: 118px;
        margin-top: 96px;
        margin-bottom: 16px;
        line-height: 32px;
        font-size: 22px;
      }
    }
  }
  .cooperation-agent-cooperate {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .cooperate-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 218px;
      margin-bottom: 150px;
      .round {
        width: 25px;
        height: 25px;
        background: #FFE50C;
        border-radius: 50%;
        margin-right: -17px;
        margin-top: 8px;
      }
      .title {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
    }
    .cooperate-content {
      display: flex;
      .cooperate-content-item {
        display: flex;
      }
      .item {
        &-apply-for {
          background-image: url('../assets/img/cooperation/合作申请@2x.png');
          background-size: 100% 100%;
          width: 174px;
          height: 216px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFE50C;
          padding-bottom: 10px;
        }
        &-talk-over {
          background-image: url('../assets/img/cooperation/合作洽谈@2x.png');
          background-size: 100% 100%;
          width: 174px;
          height: 216px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFE50C;
          padding-bottom: 10px;
        }
        &-treaty {
          background-image: url('../assets/img/cooperation/签订协议@2x.png');
          background-size: 100% 100%;
          width: 174px;
          height: 216px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFE50C;
          padding-bottom: 10px;
        }
        &-cooperate-open {
          background-image: url('../assets/img/cooperation/合作申请@2x.png');
          background-size: 100% 100%;
          width: 174px;
          height: 216px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFE50C;
          padding-bottom: 10px;
        }
        &-all-win {
          background-image: url('../assets/img/cooperation/合作共赢@2x.png');
          background-size: 100% 100%;
          width: 174px;
          height: 216px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #FFE50C;
          padding-bottom: 10px;
        }
      }
      .arrow {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 26px;
        margin-right: 21px;
        .arrow-line {
          width: 61px;
          height: 4px;
          background: #262626;
          border-radius: 2px;
        }
        .arrow-back {
          width: 0;
          height: 0;
          border-left: 13px solid #262626;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          margin-right: 6px;
          margin-left: -2px;
        }
      }
    }
    .cooperate-btn {
      font-size: 26px;
      font-family: DOUYU;
      font-weight: 400;
      color: #FFE50C;
      line-height: 46px;
      width: 238px;
      height: 62px;
      background: #262626;
      border-radius: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 154px;
      margin-bottom: 197px;
    }
  }
  .cooperation-agent-experience {
    width: 100%;
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    .experience-title {
      margin-top: 102px;
      margin-bottom: 126px;
      .title {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
      .line {
        width: 200px;
        height: 16px;
        background: #FFE50C;
        border-radius: 10px;
        margin-top: -20px;
        margin-left: -10px;
      }
    }
    .experience-tip {
      width: 1200px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 40px;
    }
    .experience-content {
      display: flex;
      flex-direction: row;
      margin-left: 15px;
      margin-top: 159px;
      margin-bottom: 164px;
      .item {
        width: 347px;
        height: 470px;
        background: #FFFFFF;
        border-radius: 30px;
        margin-right: 46px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .item-head {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 49px;
          .beitong {
            width: 41px;
            height: 32px;
          }
          .gmai {
            width: 35px;
            height: 40px;
          }
          .call {
            width: 35px;
            height: 34px;
          }
          .aide {
            width: 38px;
            height: 36px;
          }
          .item-title {
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #000000;
            margin-left: 10px;
          }
        }
        .item-tip {
          font-size: 22px;
          font-family: SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          margin-top: -16px;
        }
        .item-content {
          height: 164px;
          .content-list {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 14px;
            img {
              width: 22px;
              height: 22px;
              margin-right: 10px;
            }
            .list {
              font-size: 20px;
              font-family: SourceHanSansCN;
              font-weight: 500;
              color: #333333;
            }
          }
        }
        .item-btn {
          font-size: 22px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #FFE50C;
          width: 100%;
          height: 63px;
          background: #262626;
          border-radius: 0 0 20px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
</style>
