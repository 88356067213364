<template>
  <div
    :class="data.isImg ? 'middle-top-content-back' : 'middle-top-content'"
    >
    <div class="left-content">
      <img
        src="../../assets/img/oval@2x.png"
        alt
        class="top-img"
      />
      <img
        src="../../assets/img/展示图数据公共@2x.png"
        alt
        class="bottom-img"
      />
    </div>
    <div class="right-content">
      <div class="title" v-show="!data.isTitle">{{ data.title }}</div>
      <div class="title" v-show="data.isTitle">
        <div
          class="item-title"
          v-for="item in data.title"
          :key="item"
        >{{ item }}</div>
      </div>
      <div
        class="item"
        v-for="(item, index) in data.content"
        :key="index"
      >
        <div class="item-all" v-for="(ite) in item.text" :key="ite.id">
          <div class="item-all-img" v-if="ite.img">
            <img
              src="../../assets/img/round_right@2x.png"
              alt
            />
            <div class="item-content">{{ ite.detail }}</div>
          </div>
          <div class="item-all-text" v-if="!ite.img">{{ ite.detail }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'middle-top-content',
  props: ['data']
}
</script>

<style lang="scss" scoped>
.middle-top-content {
  width: 100%;
  height: 736px;
  background: #FFFFFF;
  display: flex;
  padding-top: 201px;
}
.middle-top-content-back {
  width: 100%;
  height: 736px;
  background: #FFFFFF url('../../assets/img/Shape_left@2x.png') no-repeat left bottom;
  background-size: 345px 367px;
  display: flex;
  padding-top: 201px;
}
.left-content {
    display: flex;
    flex-direction: column;
    margin-left: 198px;
    margin-right: 162px;
    .top-img {
      width: 96px;
      height: 113px;
      margin-left: 265px;
    }
    .bottom-img {
      width: 599.8px;
      height: 338.9px;
      margin-top: -48px;
    }
  }
  .right-content {
    margin-top: 94px;
    .title {
      font-size: 34px;
      font-family: SourceHanSansCN;
      font-weight: bold;
      color: #000000;
      margin-bottom: 73px;
      .item-title {
        margin-top: 10px;
      }
    }
    .item {
      margin-bottom: 23px;
      .item-all {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #000000;
        margin-bottom: 6px;
        .item-all-img {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 7px;
          }
        }
        .item-all-text {}
      }
    }
  }
</style>
