<template>
  <div :class="isBack ? 'left-down-content' : 'left-down-content-back'">
    <div class="left-content">
      <img
        src="../../assets/img/oval@2x.png"
        alt
        class="left-img"
      />
      <img
        src="../../assets/img/展示图数据公共@2x.png"
        alt
        class="right-img"
      />
    </div>
    <div class="right-content">
      <div class="title" v-if="!isBack">{{ data.title }}</div>
      <div class="title-all" v-if="isBack">
        <div
          class="item"
          v-for="(iteTit, indTit) in data.title"
          :key="indTit"
        >{{ iteTit }}</div>
      </div>
      <div
        class="item"
        v-for="(item, index) in data.content"
        :key="index"
      >
        <div class="item-all" v-for="(ite) in item.text" :key="ite.id">
          <div class="item-all-img" v-if="ite.img">
            <img
              src="../../assets/img/round_right@2x.png"
              alt
            />
            <div class="item-content">{{ ite.detail }}</div>
          </div>
          <div class="item-all-text" v-if="!ite.img">{{ ite.detail }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'left-down-content',
  props: ['data', 'isBack']
}
</script>

<style lang="scss" scoped>
.left-down-content {
  width: 100%;
  height: 675px;
  background: #FFFFFF;
  display: flex;
  padding-top: 139px;
  padding-left: 234px;
}
.left-down-content-back {
  width: 100%;
  height: 675px;
  background: #FFFFFF url('../../assets/img/Shape_right@2x.png') top right no-repeat;
  background-size: 355px 367px;
  display: flex;
  padding-top: 139px;
  padding-left: 162px;
}
.left-content {
    margin-right: 157px;
    .left-img {
      width: 96px;
      height: 123px;
      margin-right: -70px;
      margin-bottom: -32px;
    }
    .right-img {
      width: 607.3px;
      height: 349px;
    }
}
.right-content {
  color: #000000;
  margin-top: 57px;
    .title {
      font-size: 34px;
      font-family: SourceHanSansCN;
      font-weight: bold;
      margin-bottom: 73px;
    }
    .title-all {
      font-family: SourceHanSansCN;
      font-weight: bold;
      margin-bottom: 73px;
      .item {
        font-size: 34px;
        margin-bottom: 6px;
        font-weight: bold;
      }
    }
    .item {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: 500;
      margin-bottom: 30px;
      .item-all {
        display: flex;
        flex-direction: column;
        .item-all-img {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          img {
            width: 20px;
            height: 20px;
            margin-right: 7px;
          }
        }
        .item-all-text {
          margin-bottom: 10px;
        }
      }
    }
}
</style>
