<template>
  <div class="online-trade">
    <web-header />
    <el-carousel
      :autoplay="false"
      class="online-trade-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="online-trade-head">
          <div class="online-trade-head-left">
            <div class="online-trade-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">电商行业</div>
            </div>
            <div class="online-trade-head-content">
              在多元化发展的背景下，助力电商行业精细化营销，激活用户增长，高效获客
            </div>
          </div>
          <img
            src="../assets/img/trade/online_retailers@2x.png"
            alt="电商行业图"
            class="online-trade-head-right"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
    <need-battle :data="needData" />
    <left-down-content :data="leftDownData" :isBack="true" />
    <right-top-content :data="rightTopData" />
    <middle-top-content :data="middleTopData" />
    <web-cooperate title="合作客户" tip="Cooperative customers" />
    <div class="home-footer">
      <web-footer />
    </div>
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import needBattle from '../components/NeedBattle/index.vue'
import leftDownContent from '../components/LeftDownContent/index.vue'
import rightTopContent from '../components/RightTopContent/index.vue'
import middleTopContent from '../components/MiddleTopContent/index.vue'
import webCooperate from '../components/WebCooperate/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'online-trade',
  components: {
    webHeader,
    needBattle,
    leftDownContent,
    rightTopContent,
    middleTopContent,
    webCooperate,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      needData: [
        {
          id: 1,
          img: require('../assets/img/trade/online_one@2x.png'),
          imgName: 'online-one',
          text: [
            '粉丝数量庞大，活动预售通知',
            '需要与粉丝快速建立联系，增',
            '加销售能力'
          ]
        },
        {
          id: 2,
          img: require('../assets/img/trade/online_two@2x.png'),
          imgName: 'online-two',
          text: [
            '针对直播电商，需要不断与',
            '粉丝互动，增加粉丝粘性'
          ]
        },
        {
          id: 3,
          img: require('../assets/img/trade/online_three@2x.png'),
          imgName: 'online-three',
          text: [
            '招聘官管理数据过于杂乱，不能',
            '及时更新，难以全面分析'
          ]
        }
      ],
      leftDownData: {
        title: ['电商活动通知、邀约', '及猫云智能机器人批量呼叫'],
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '深度利用AI技术，匹配主播本人声音，模拟真人进行交互式'
              },
              {
                id: 2,
                img: false,
                detail: '对话，粉丝印象更深，增加活动到场率。'
              }
            ]
          }
        ]
      },
      rightTopData: {
        title: ['AI语言加粉', '及猫云智能机器人高效筛客'],
        marginRight: '122px',
        marginTop: '70px',
        isBack: true,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: 'AI语音机器人外呼自动筛选出有意向加微的粉丝，自行'
              },
              {
                id: 2,
                img: false,
                detail: '判断意向强弱并且按照等级划分出来，便于后期运营跟进。'
              }
            ]
          }
        ]
      },
      middleTopData: {
        title: ['数据回流打造私域', '及猫云企微助手进行加粉'],
        isTitle: true,
        isImg: false,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '对粉丝实时进行AI语音售后外呼，引导粉丝加微'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '有意向加微的粉丝手机号实时反馈到企业微信，由运营'
              },
              {
                id: 2,
                img: false,
                detail: '或客服人员安排进行微信添加，形成私域。'
              }
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.online-trade {
  width: 100%;
  background: #F8F8F8;
  .online-trade-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .online-trade-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 747px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-left: 180px;
    padding-right: 261px;
    padding-bottom: 150px;
    .online-trade-head-left {
      .online-trade-head-title {
        display: flex;
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 25px 0px 0 -4px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 60px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #071B3C;
          z-index: 1;
          margin-left: -24px;
        }
      }
      .online-trade-head-content {
        font-size: 30px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #071B3C;
        line-height: 47px;
        margin: 56px 0 0 0;
        width: 790px;
      }
    }
    .online-trade-head-right {
      width: 506px;
      height: 471px;
      margin-top: 60px;
    }
  }
}
</style>
