<template>
  <div class="education-sector">
    <web-header />
    <el-carousel
      height="747px"
      :autoplay="false"
      class="education-sector-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="education-sector-head">
          <div class="education-sector-head-left">
            <div class="education-sector-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">教育行业</div>
            </div>
            <div class="education-sector-head-content">
              <div class="item">教育行业竞争激烈，日常投入大量预算用于活动邀约、学</div>
              <div class="item">员回访等客户沟通，但目前员工离职率高且人工呼叫效率</div>
              <div class="item">低下，新客户增长出现瓶颈。</div>
            </div>
          </div>
          <img
            src="../assets/img/trade/education_sector@2x.png"
            alt="财税行业图"
            class="education-sector-head-right"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
    <need-battle :data="needData" />
    <left-down-content :data="leftDownData" :isBack="true" />
    <right-top-content :data="rightTopData" />
    <middle-top-content :data="middleTopData" />
    <web-cooperate title="合作客户" />
    <div class="home-footer">
      <web-footer />
    </div>
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import needBattle from '../components/NeedBattle/index.vue'
import leftDownContent from '../components/LeftDownContent/index.vue'
import rightTopContent from '../components/RightTopContent/index.vue'
import middleTopContent from '../components/MiddleTopContent/index.vue'
import webCooperate from '../components/WebCooperate/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'education-sector',
  components: {
    webHeader,
    needBattle,
    leftDownContent,
    rightTopContent,
    middleTopContent,
    webCooperate,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      needData: [
        {
          id: 1,
          img: require('../assets/img/trade/education_one@2x.png'),
          imgName: 'education-one',
          text: [
            '离职率高且人工呼叫效率低下',
            '快速筛客成了新需求'
          ]
        },
        {
          id: 2,
          img: require('../assets/img/trade/education_two@2x.png'),
          imgName: 'education-two',
          text: [
            '销售触达与付费转化都至关重',
            '要，所以及时触达客户，提高成',
            '单转化率'
          ]
        },
        {
          id: 3,
          img: require('../assets/img/trade/education_three@2x.png'),
          imgName: 'education-three',
          text: [
            '后期学员复购以及私域的打造',
            '存量用户精细化运营的重要性',
            '越来越高'
          ]
        }
      ],
      leftDownData: {
        title: ['全网采集海量线索', '及猫云掘贝通深度挖掘企业目标客户'],
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '依托3亿+的企业数据覆盖能力，根据企业目标客户画像'
              },
              {
                id: 2,
                img: false,
                detail: '关键词智能检索，一键获取海量目标客户'
              }
            ]
          }
        ]
      },
      rightTopData: {
        title: ['每日可拨打上千个+电话', '及猫云智能机器人GMAI快速筛客，降本增效'],
        marginRight: '112px',
        marginTop: '56px',
        isBack: true,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '智能机器人GMAI快速筛选线索，从海量目标客户群中，'
              },
              {
                id: 2,
                img: false,
                detail: '甄别出有需求的客户，并且进行意向等级分类'
              }
            ]
          }
        ]
      },
      middleTopData: {
        title: ['高效触客', '及猫云呼叫系统任务呼叫，提升效率'],
        isTitle: true,
        isImg: false,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '针对意向客户可进行人工坐席跟进'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '通过及猫云呼叫系统，可任务呼叫，一个呼叫任务结束立马'
              },
              {
                id: 2,
                img: false,
                detail: '进行下一个任务，省去拨号时间，提升工作效率，快速触达客户'
              }
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.education-sector {
  width: 100%;
  background: #F8F8F8;
  .education-sector-carousel {
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .education-sector-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 747px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-left: 190px;
    padding-right: 285px;
    padding-bottom: 120px;
    .education-sector-head-left {
      .education-sector-head-title {
        display: flex;
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -4px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 60px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #071B3C;
          z-index: 1;
          margin-left: -24px;
        }
      }
      .education-sector-head-content {
        font-size: 24px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #071B3C;
        line-height: 47px;
        margin: 39px 0 0 0;
      }
    }
    .education-sector-head-right {
      width: 450px;
      height: 420px;
      margin-top: 20px;
    }
  }
}
</style>
