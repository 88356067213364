<template>
  <div class="call-system">
    <web-header />
    <el-carousel
      :autoplay="false"
      class="call-system-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="call-system-head">
          <div class="call-system-head-left">
            <div class="call-system-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">及猫云呼叫系统</div>
            </div>
            <div class="call-system-head-content">
              智能化客户管理与销售管理，高效触达客户，降本增效解决企业电销难题，提升企业业绩，助力传统企业数字化转型。
            </div>
            <div class="call-system-btn">立即体验</div>
          </div>
          <img
            src="../assets/img/call-system/call_system@2x.png"
            alt="首页电视图"
            class="call-system-right-img"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
    <div class="call-system-services">
      <div class="services-title">
        <div class="services-round" />
        <div class="services-tit">业务痛点</div>
      </div>
      <div class="serveices-content-back">
        <img
          src="../assets/img/back-left@2x.png"
          alt
          class="serveices-content-left-img"
        />
        <img
          src="../assets/img/back_right@2x.png"
          alt
          class="serveices-content-right-img"
        />
        <div class="serveices-content">
          <div
            class="services-content-item"
            v-for="item in servicesData"
            :key="item.id"
            :style="{ background: item.back }"
          >
            <img
              :src="item.img"
              alt
              class="item-left-img"
              :style="{ width: item.imgWidth, height: item.imgHeight }"
            />
            <div class="item-right">
              <div class="ite-right-tit">{{ item.tit }}</div>
              <div class="ite-right-content">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <left-down-content :data="leftDownData" />
    <right-top-content :data="rightTopData" />
    <middle-top-content :data="middleTopData" />
    <div class="call-system-electric">
      <div class="electric-title">
        <div class="round" />
        <div class="title">更多产品功能 助力企业解决不同电销场景</div>
      </div>
      <div class="electric-content">
        <div
          class="electric-content-item"
          v-for="item in electricData"
          :key="item.id"
        >
          <!-- <img
          :src="item.img"
          :style="{ width: item.imgWidth, height: item.imgHeight }"
          >
          <div class="item-title">{{ item.tit }}</div>
          <div
            class="item-content"
            :style="{ width: item.contWidth }"
          >
            {{ item.content }}
          </div> -->
          <img
          :src="item.img"
          >
          <div class="item-title">{{ item.tit }}</div>
          <div
            class="item-content"
          >
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="electric-footer">
      <web-footer />
    </div>
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import leftDownContent from '../components/LeftDownContent/index.vue'
import rightTopContent from '../components/RightTopContent/index.vue'
import middleTopContent from '../components/MiddleTopContent/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'call-system',
  components: {
    webHeader,
    leftDownContent,
    rightTopContent,
    middleTopContent,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      servicesData: [
        {
          id: 1,
          img: require('../assets/img/call-system/质量成本@2x.png'),
          tit: '成本高',
          content: '需要购买昂贵设备，电销成本日益增高，企业电销团队投入和产出不成正比',
          imgWidth: '44px',
          imgHight: '48px',
          back: '#FFFFFF'
        },
        {
          id: 2,
          img: require('../assets/img/call-system/客户管理@2x.png'),
          tit: '管理难',
          content: '人工电销无法记录销售过程，数据不透明，管理员需要花费大量时间 客户跟踪进程不及时，容易导致客户流失',
          imgWidth: '55px',
          imgHight: '51px',
          back: '#FFE50C'
        },
        {
          id: 3,
          img: require('../assets/img/call-system/SEN003@2x.png'),
          tit: '触达低',
          content: '线路单一不稳定，外呼频次过多导致接通率不高，触达客户率低，影响业务员的销售进度',
          imgWidth: '35px',
          imgHight: '51px',
          back: '#FFE50C'
        },
        {
          id: 4,
          img: require('../assets/img/call-system/KHCFDC_业绩@2x.png'),
          tit: '业绩低',
          content: '号码接通率低，成交转化难，导致业绩增长慢',
          imgWidth: '53px',
          imgHight: '49px',
          back: '#FFFFFF'
        }
      ],
      leftDownData: {
        title: '线路丰富稳定，提高接通率',
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '三大运营商战略合作 系统稳定可靠'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '外显真实手机号码，可自定义归属地，提高接通率'
              }
            ]
          },
          {
            id: 3,
            text: [
              {
                id: 1,
                img: true,
                detail: '采用回拨技术，防止高频外呼，降低封号率'
              }
            ]
          }
        ]
      },
      rightTopData: {
        title: '通话录音下载，智能管理销售',
        marginRight: '166px',
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '系统自带通话录音功能，可在线听取也可下载存档'
              },
              {
                id: 2,
                img: false,
                detail: '便于销售复盘学习话术，提升业务员销售技巧，以及'
              },
              {
                id: 3,
                img: false,
                detail: '方便管理者进行通话质检'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '呼叫总报表汇总数据，销售进展一目了然，实时掌'
              },
              {
                id: 2,
                img: false,
                detail: '握销售业绩情况'
              }
            ]
          },
          {
            id: 3,
            text: [
              {
                id: 1,
                img: true,
                detail: '赠送T-CRM系统，对客户实现全周期地记录与分层'
              },
              {
                id: 2,
                img: false,
                detail: '分类管理'
              }
            ]
          }
        ]
      },
      middleTopData: {
        title: '低成本、高效果，灵活搭建，快捷操作',
        isImg: true,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '打开网页即可呼叫，简单便捷，控制成本'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '1s呼叫，节省操作时间，提升外呼作业效率'
              }
            ]
          },
          {
            id: 3,
            text: [
              {
                id: 1,
                img: true,
                detail: '可设置任务呼叫，一个客户沟通结束后，系电话接通。'
              },
              {
                id: 2,
                img: false,
                detail: '统自动进行下一个呼叫，坐席只需等待'
              }
            ]
          }
        ]
      },
      electricData: [
        {
          id: 1,
          img: require('../assets/img/call-system/leading_in@2x.png'),
          // imgWidth: '76px',
          // imgHeight: '73px',
          tit: '批量导入',
          content: '一键勾选所有线索自动导入呼叫系统'
          // contWidth: '190px'
        },
        {
          id: 2,
          img: require('../assets/img/call-system/report21@2x.png'),
          // imgWidth: '68px',
          // imgHeight: '71px',
          tit: '报表统计',
          content: '详细记录通话明细和各类数据'
          // contWidth: '190px'
        },
        {
          id: 3,
          img: require('../assets/img/call-system/call_phone@2x.png'),
          // imgWidth: '69px',
          // imgHeight: '72px',
          tit: '失败重呼',
          content: '呼叫失败可自动拨打重新呼叫'
          // contWidth: '214px'
        },
        {
          id: 4,
          img: require('../assets/img/call-system/call_news@2x.png'),
          // imgWidth: '73px',
          // imgHeight: '69px',
          tit: '话术模板',
          content: '根据不同销售场景可提供话术模板'
          // contWidth: '214px'
        },
        {
          id: 5,
          img: require('../assets/img/call-system/api21@2x.png'),
          // imgWidth: '75px',
          // imgHeight: '69px',
          tit: '接口能力',
          content: '开放API接口快速接入到客户自由系统，让客户的系统具备云电销能力'
          // contWidth: '251px'
        },
        {
          id: 6,
          img: require('../assets/img/call-system/safe21@2x.png'),
          // imgWidth: '58px',
          // imgHeight: '70px',
          tit: '安全保障',
          content: '保护客户的数据安全隐私安全、通讯安全'
          // contWidth: '216px'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.call-system {
  width: 100%;
  background: #F8F8F8;
  .call-system-carousel {
    ::v-deep .el-carousel__container {
      height: 747px;
    }
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .call-system-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 746px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-bottom: 150px;
    .call-system-head-left {
      font-family: SourceHanSansCN;
      color: #071B3C;
      margin-left: 86px;
      margin-top: -30px;
      .call-system-head-title {
        font-size: 60px;
        display: flex;
        flex-direction: row;
        font-weight: 800;
        .title {
          z-index: 1;
          margin-left: -32px;
        }
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -10px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
         .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
      }
      .call-system-head-content {
        font-size: 24px;
        font-weight: 400;
        margin-top: 44px;
        width: 858px;
      }
      .call-system-btn {
        font-size: 22px;
        font-family: DOUYU;
        font-weight: 400;
        color: #FFE50C;
        width: 195px;
        height: 61px;
        background: #262626;
        border-radius: 31px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 63px;
      }
    }
    .call-system-right-img {
      width: 486px;
      height: 497px;
      margin-right: 186px;
    }
  }
  .call-system-services {
    margin-top: 160px;
    padding-bottom: 144px;
    .services-title {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 64px;
      margin-bottom: 159px;
      .services-round {
        width: 25px;
        height: 25px;
        background: #FFD400;
        border-radius: 50%;
        margin: 16px -20px 0 0;
      }
      .services-tit {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
    }
    .serveices-content-back {
      width: 100%;
      height: 590px;
      position: relative;
      display: flex;
      flex-direction: column;
      .serveices-content-left-img {
        width: 212px;
        height: 300px;
      }
      .serveices-content-right-img {
        width: 241px;
        height: 300px;
        align-self: flex-end;
        margin-top: 5px;
      }
      .serveices-content {
        position: absolute;
        top: 0;
        left: 0;
        margin-left: 139px;
        display: flex;
        flex-wrap: wrap;
        .services-content-item {
          width: 776px;
          height: 245px;
          border: 4px solid #000000;
          border-radius: 30px;
          margin-right: 82px;
          margin-bottom: 55px;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-right: 45px;
          .item-left-img {
            margin-left: 58px;
            margin-right: 51px;
          }
          .item-right {
            color: #000000;
            .ite-right-tit {
              font-size: 30px;
              font-family: SourceHanSansCN;
              margin-bottom: 31px;
              font-weight: bold;
            }
            .ite-right-content {
              font-size: 22px;
              font-family: PingFang SC;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .call-system-electric {
    .electric-title {
      display: flex;
      justify-content: center;
      margin-top: 146px;
      margin-bottom: 144px;
      .round {
        width: 25px;
        height: 25px;
        background: #FFD400;
        border-radius: 50%;
        margin-right: -18px;
        margin-top: 20px;
      }
      .title {
        font-size: 45px;
        font-family: SourceHanSansCN;
        font-weight: bold;
        color: #000000;
      }
    }
    .electric-content {
      display: flex;
      flex-wrap: wrap;
      margin-left: 270px;
      .electric-content-item {
        width: 390px;
        height: 384px;
        background: #FFFFFF;
        border-radius: 30px;
        margin-right: 78px;
        margin-bottom: 59px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        img {
          margin-top: 60px;
          width: 69px;
          height: 71px;
        }
        .item-title {
          font-size: 26px;
          font-family: SourceHanSansCN;
          font-weight: bold;
          color: #000000;
          margin-top: 42px;
          margin-bottom: 47px;
        }
        .item-content {
          font-size: 22px;
          font-family: SourceHanSansCN;
          font-weight: 500;
          color: #000000;
          width: 235px;
        }
      }
    }
  }
  .electric-footer {
    width: 100%;
    background: #FFFFFF;
    margin-top: 105px;
  }
}
</style>
