<template>
  <div class="finance-taxation">
    <web-header />
    <el-carousel
      height="747px"
      :autoplay="false"
      class="finance-taxation-carousel"
      arrow="never"
    >
      <el-carousel-item
        v-for="item in 3"
        :key="item"
      >
        <div class="finance-taxation-head">
          <div class="finance-taxation-head-left">
            <div class="finance-taxation-head-title">
              <div class="round">
                <div class="round-one" />
                <div class="round-two" />
              </div>
              <div class="title">财税行业</div>
            </div>
            <div class="finance-taxation-head-content">
              <div class="item">在万物互联网的大趋势下，“互联网+财税”思维和互联网</div>
              <div class="item">新技术的出现，为各大财税企业上云做了铺垫。传统的工</div>
              <div class="item">具与管理已经无法满足日益壮大的市场发展，推动效率提</div>
              <div class="item">升和管理变革，是实现数字化的必由之路。</div>
            </div>
          </div>
          <img
            src="../assets/img/trade/finance_and_taxation@2x.png"
            alt="财税行业图"
            class="finance-taxation-head-right"
          >
        </div>
      </el-carousel-item>
    </el-carousel>
    <need-battle :data="needData" />
    <left-down-content :data="leftDownData" :isBack="true" />
    <right-top-content :data="rightTopData" />
    <middle-top-content :data="middleTopData" />
    <web-cooperate title="合作客户" />
    <div class="home-footer">
      <web-footer />
    </div>
    <fixed-dialog />
    <fixed-concat />
  </div>
</template>

<script>
import webHeader from '../components/WebHeader/index.vue'
import needBattle from '../components/NeedBattle/index.vue'
import leftDownContent from '../components/LeftDownContent/index.vue'
import rightTopContent from '../components/RightTopContent/index.vue'
import middleTopContent from '../components/MiddleTopContent/index.vue'
import webCooperate from '../components/WebCooperate/index.vue'
import webFooter from '../components/WebFooter/index.vue'
import fixedDialog from '../components/FixedDialog/index.vue'
import fixedConcat from '../components/FixedConcat/index.vue'

export default {
  name: 'finance-taxation',
  components: {
    webHeader,
    needBattle,
    leftDownContent,
    rightTopContent,
    middleTopContent,
    webCooperate,
    webFooter,
    fixedDialog,
    fixedConcat
  },
  data () {
    return {
      needData: [
        {
          id: 1,
          img: require('../assets/img/trade/finance_one@2x.png'),
          imgName: 'finance-one',
          text: [
            '传统的获客方式单一，缺乏优',
            '质的获客渠道，拓客难'
          ]
        },
        {
          id: 2,
          img: require('../assets/img/trade/finance_two@2x.png'),
          imgName: 'finance-two',
          text: [
            '网络营销成本攀升，人力成',
            '本持续增长，成本高'
          ]
        },
        {
          id: 3,
          img: require('../assets/img/trade/finance_three@2x.png'),
          imgName: 'finance-three',
          text: [
            '需要花费大量时间找客，浪费',
            '时间无效沟通，人效低'
          ]
        }
      ],
      leftDownData: {
        title: ['全网采集海量线索', '及猫云掘贝通深度挖掘企业目标客户'],
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '依托3亿+的企业数据覆盖能力，根据企业目标客户画像'
              },
              {
                id: 2,
                img: false,
                detail: '关键词智能检索，一键获取海量目标客户'
              }
            ]
          }
        ]
      },
      rightTopData: {
        title: ['每日可拨打上千个+电话', '及猫云智能机器人GMAI快速筛客，降本增效'],
        marginRight: '112px',
        marginTop: '56px',
        isBack: true,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '智能机器人GMAI快速筛选线索，从海量目标客户群中，'
              },
              {
                id: 2,
                img: false,
                detail: '甄别出有需求的客户，并且进行意向等级分类'
              }
            ]
          }
        ]
      },
      middleTopData: {
        title: ['高效触客', '及猫云呼叫系统任务呼叫，提升效率'],
        isTitle: true,
        isImg: false,
        content: [
          {
            id: 1,
            text: [
              {
                id: 1,
                img: true,
                detail: '针对意向客户可进行人工坐席跟进'
              }
            ]
          },
          {
            id: 2,
            text: [
              {
                id: 1,
                img: true,
                detail: '通过及猫云呼叫系统，可任务呼叫，一个呼叫任务结束立马'
              },
              {
                id: 2,
                img: false,
                detail: '进行下一个任务，省去拨号时间，提升工作效率，快速触达客户'
              }
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.finance-taxation {
  width: 100%;
  background: #F8F8F8;
  .finance-taxation-carousel {
    ::v-deep .el-carousel__indicators--horizontal {
      top: 650px;
    }
    ::v-deep .el-carousel__indicator.is-active button {
      background: #FFFFFF;
    }
  }
  .finance-taxation-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 747px;
    background-image: url('../assets/img/backImg@2x.png');
    background-size: 100% 100%;
    padding-left: 180px;
    padding-right: 234px;
    padding-bottom: 100px;
    .finance-taxation-head-left {
      .finance-taxation-head-title {
        display: flex;
        .round {
          position: relative;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin: 30px 0px 0 -4px;
          .round-one {
            width: 100%;
            height: 100%;
            background: #EBCA57;
            border-radius: 50%;
          }
          .round-two {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #FFFFFF;
            border-radius: 50%;
            opacity: 0.58;
          }
        }
        .title {
          font-size: 60px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #071B3C;
          z-index: 1;
          margin-left: -24px;
        }
      }
      .finance-taxation-head-content {
        font-size: 24px;
        font-family: SourceHanSansCN;
        font-weight: 500;
        color: #071B3C;
        line-height: 47px;
        margin: 49px 0 0 0;
      }
    }
    .finance-taxation-head-right {
      width: 625px;
      height: 417px;
      margin-top: -20px;
    }
  }
}
</style>
